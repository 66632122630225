import httpService from '@viteplan/services/http.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendMessage = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`message`, body, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createChatGroup = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`message/group`, body, config).then((res: any) => res);
};

export const groupMessage = (
  eventGroupId: number | undefined,
  guestId: number | undefined,
  userId: number | undefined,
  config = {}
) => {
  let url = `message/group?eventGroupId=${eventGroupId}`;

  if (guestId !== undefined) {
    url += `&guestId=${guestId}`;
  } else if (userId !== undefined) {
    url += `&userId=${userId}`;
  }

  return (
    httpService
      .get(url, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const getChatGroupMessages = (groupId: number | undefined, pageId: number, config = {}) => {
  return (
    httpService
      .get(`message/group/${groupId}/messages/${pageId}`, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGroupChatMembers = (groupId: number, body: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`message/group/${groupId}/members`, body).then((res: any) => res);
};
