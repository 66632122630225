import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * @memberof Buttons
 */
/**
 * The props type for {@link LinkButton}
 *
 * @interface
 *
 * The props type is defined as a separate interface
 *
 * ```tsx
 *  export interface ILinkCardProps {
 *    // ...
 *  }
 * ```
 */
export interface ILinkButtonProps {
  /** The text of the button. */
  label: string;

  /** The link of the button. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: any;

  /** The state of the navigation from where it came from. Defaults to `null`.*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
}

/**
 * Renders a LinkButton around some content.
 *
 * ```tsx
 *  <LinkButton label="label" link={link} />
 * ```
 *
 * Function implementation
 *
 *```tsx
 * export function LinkButton({
 *          label,
 *          link,
 *          state = null
 *        }: ILinkButtonProps): React.JSX.Element {
 *  // ...
 *  }
 * ```
 *
 *  @category Component
 */
export function LinkButton({ label, link, state = null }: ILinkButtonProps): React.JSX.Element {
  return (
    <NavLink className="btn primary" to={link} style={{ textDecoration: 'none' }} state={state}>
      <span>{label}</span>
    </NavLink>
  );
}
