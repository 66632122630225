/**
 * @summary The base URL for the Viteplan API.
 * @constant {string}
 */

const baseurl = 'https://viteplan.com/viteplan-api';
// export const id = JSON.parse(localStorage.getItem('user')).data.id;
export default baseurl;

/**
 * @summary Retrieves the request info URL for a given event group unique key.
 *
 * @param {string} eventGroupUniqueKey - The unique key of the event group.
 * @returns {string} - The request info URL.
 */

export const getRequestInfoUrl = (eventGroupUniqueKey: string | undefined) => {
  return window.location.protocol + '//' + window.location.host + '/addr/' + eventGroupUniqueKey;
};

export const getWeddingWebsiteUrl = (eventGroupUniqueKey: string | undefined) => {
  return window.location.protocol + '//' + window.location.host + '/web/' + eventGroupUniqueKey;
};

export const getGreetingLink = (eventGroupUniqueKey: string | undefined) => {
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    '/guest-view/memories/' +
    eventGroupUniqueKey
  );
};
