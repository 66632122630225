import React from 'react';
import { LinkButton } from '../../Buttons';

/**
 * @memberof Cards
 */
/**
 * The props type for {@link TemplateCard}.
 *
 * The props type is defined as a separate interface
 *
 * ```tsx
 *  export interface ITemplateCardProps {
 *    // ...
 *  }
 * ```
 * @interface
 */
export interface ITemplateCardProps {
  /** The Object Contains thumbnailLocation, name and description of the card */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;

  /** The link of the button. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link?: string;
}

/**
 * Renders a TemplateCard around some content.
 *
 * ```tsx
 *  <TemplateCard item={item} link={link} />
 * ```
 *
 * Function implementation
 *
 * ```tsx
 *  export function TemplateCard({props}: ITemplateCardProps): React.JSX.Element {
 *    // ...
 *  }
 * ```
 *
 *
 *
 * @Category Component
 */

export function TemplateCard({ ...props }: ITemplateCardProps): React.JSX.Element {
  const { item, link } = props;
  const envelopeUrl = item?.templateAssetsLocation + '/envelpe.png';
  return (
    <>
      <div className="invitation-card">
        <div
          className="invitation_card_show with_envelope d-flex justify-content-end"
          style={{ backgroundImage: `url(${envelopeUrl})` }}>
          <img className="w-100 h-100" src={item?.thumbnailLocation || ''} alt={item?.name} />
          <div className="card-hover-button-wrapper w-100 text-center">
            <div className="btn_panel d-flex justify-content-center align-items-center">
              <LinkButton label="Select" link={link} />
              <LinkButton label="View Detail" link={link} />
            </div>
          </div>
        </div>
        <div className="title_small" style={{ color: '#bc8129' }}>
          {item?.name}
        </div>
      </div>
    </>
  );
}
