import httpService from '@viteplan/services/http.service';
import { AxiosResponse } from 'axios';

export const videoInvitationOrderDetails = (orderId: number) => {
  return httpService.get(`order/get/${orderId}`).then((res: AxiosResponse) => res);
};

export const applyOrderCouponCode = (orderId: number, couponCode: string) => {
  return httpService
    .get(`order/applyDiscount/${orderId}/${couponCode}`)
    .then((res: AxiosResponse) => res);
};

export const orderListByUser = (userId: number, pageNo: number, pageSize: number) => {
  return httpService
    .get(`order/user/${userId}?pageNo=${pageNo}&pageSize=${pageSize}`)
    .then((res: AxiosResponse) => res);
};

export const getOrderByUniqueId = (orderId: string) => {
  return httpService.get(`order/getByOrderId/${orderId}`).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const orderHistory = (orderId: number, body: any, config = {}) => {
  return httpService
    .post(`order/${orderId}/order-history`, body, config)
    .then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const orderBilingAddresss = (orderId: number, body: any) => {
  return httpService
    .post(`order/${orderId}/billing-address`, body)
    .then((res: AxiosResponse) => res);
};
