import httpService from '@viteplan/services/http.service';
import { AxiosResponse } from 'axios';
import { Vendor, VendorEnquiry } from '../models/Vendor.model';

export const vendorSignup = (body: Vendor, config = {}) => {
  return httpService.post(`vendor/register`, body, config).then((res: AxiosResponse) => res);
};

export const vendorList = (pageId: number, pageSize: number, isActive: boolean) => {
  return httpService.get(
    `/vendor/requests?pageNo=${pageId}&pageSize=${pageSize}&isActive=${isActive}`
  );
};

export const messageToVendor = (body: VendorEnquiry, config = {}) => {
  return httpService.post(`vendor/inquiry`, body, config).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorListWithFilters = (body: any, config = {}) => {
  return httpService.post(`vendor/requests/filter`, body, config).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorLogin = (body: any, config = {}) => {
  return httpService.post(`vendor/login`, body, config).then((res: AxiosResponse) => res);
};

export const vendorById = (requestId: number) => {
  return httpService.get(`vendor/requests/${requestId}`).then((res: AxiosResponse) => res);
};

export const vendorByUniqueKey = (uniqueKey: string) => {
  return httpService.get(`vendor/getByKey/${uniqueKey}`).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorUploads = (vendorId: number, body: any) => {
  return httpService.post(`vendor/upload/${vendorId}`, body).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorForgotPassword = (body: any, config = {}) => {
  return httpService.post(`vendor/forgot-password`, body, config).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorResetPassword = (body: any, config = {}) => {
  return httpService.post(`vendor/set-password`, body, config).then((res: AxiosResponse) => res);
};

export const verifyVendorAccountWithToken = (tempToken: string) => {
  return httpService.get(`vendor/verify-vendor/${tempToken}`).then((res: AxiosResponse) => res);
};

export const getVerfiedVendorForForgotPassword = (uniqueId: string, config = {}) => {
  return (
    httpService
      .get(`vendor/forgot-password-vendor/${uniqueId}`, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const verifyVendorForForgotPassword = (uniqueId: string, config = {}) => {
  return (
    httpService
      .get(`vendor/forgot-password-vendor/${uniqueId}/check`, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorDeleteImageUploads = (config = {}) => {
  return httpService.delete(`vendor/vendor-image`, config).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorConfirmEmail = (body: any) => {
  return httpService.post(`vendor/confirm-email`, body).then((res: AxiosResponse) => res);
};

export const vendorCity = (city: string) => {
  return httpService.get(`vendor/vendor-cities?search=${city}`).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorProfileImage = (vendorId: number, body: any) => {
  return httpService.post(`vendor/profilePic/${vendorId}`, body);
};
