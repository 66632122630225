import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const RequireAuth = ({ role }: { role: string }) => {
  const { auth } = useAuth();
  const location = useLocation();

  if (auth?.user && auth.USER_TYPE === role) {
    return <Outlet />;
  }

  // Redirect unauthorized users to the login page
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
