/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

const axiosParams: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL
};

const axiosInstance = axios.create(axiosParams);

// Function to get the authorization token from localStorage
const getAuthTokenFromLocalStorage = () => {
  return localStorage.getItem('authToken');
};

// Interceptor to modify the headers before each request
axiosInstance.interceptors.request.use((config) => {
  const authToken = getAuthTokenFromLocalStorage();

  if (authToken && authToken !== 'na') {
    config.headers.Authorization = `Basic ${authToken}`;
  }
  return config;
});

const httpService = (axios: any) => {
  return {
    get: (url: string, config = {}) => axios.get(url, config),
    post: (url: string, body: any, config = {}) => axios.post(url, body, config),
    put: (url: string, body: any, config = {}) => axios.put(url, body, config),
    patch: (url: string, body: any, config = {}) => axios.patch(url, body, config),
    delete: (url: string, config = {}) => axios.delete(url, config)
  };
};

export default httpService(axiosInstance);
