import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addGuestGroup,
  getRequestInfoTemplate,
  getGuestGroupByGuestEmailId
} from '../service/address.service';

export const getGuestGroupDataByGuestEmailId = createAsyncThunk(
  'getGuestGroupByGuestEmailId',
  async ({ eventGroupId, emailId }: { eventGroupId: number; emailId: string }) => {
    const response = await getGuestGroupByGuestEmailId(eventGroupId, emailId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGuestGroupData = createAsyncThunk('addGuestGroupData', async (data: any) => {
  const response = await addGuestGroup(data);
  return response.data;
});

//Get the RequestedTemplate Data
export const getRequestTemplateData = createAsyncThunk(
  'requestTemplate',
  async (eventUniqueKey: string | undefined) => {
    const response = await getRequestInfoTemplate(eventUniqueKey);
    return response.data;
  }
);

interface AddressCollector {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guestGroup: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestInfo: any;
  loading: boolean;
  error: string | null;
  visibleScreen: string;
}

const initialState: AddressCollector = {
  guestGroup: {},
  requestInfo: {},
  loading: false,
  error: null,
  visibleScreen: 'addressCollectorLogin'
};

export const addressCollectorSlice = createSlice({
  name: 'addressCollector',
  initialState,
  reducers: {
    setVisibleScreen(state, action) {
      state.visibleScreen = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Get the Guest Group By Id
    builder.addCase(getGuestGroupDataByGuestEmailId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGuestGroupDataByGuestEmailId.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.guestGroup = data;
    });
    builder.addCase(getGuestGroupDataByGuestEmailId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Post Get Group Data
    builder.addCase(addGuestGroupData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addGuestGroupData.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.guestGroup = data;
    });
    builder.addCase(addGuestGroupData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //get the Request Info Template
    builder.addCase(getRequestTemplateData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRequestTemplateData.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.requestInfo = data;
    });
    builder.addCase(getRequestTemplateData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export const { setVisibleScreen } = addressCollectorSlice.actions;
export default addressCollectorSlice.reducer;
