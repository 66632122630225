import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * Component for the unauthorized menu in the website header.
 *
 * The UnauthorizedMenu component displays the menu options for users who are not logged in.
 * It includes options for accessing the home page, planning and inviting,
 * as well as accessing resources. The menu items are rendered using NavLink from React Router,
 * allowing users to navigate to different pages within the website.
 * The component is used within the Header component to display the unauthorized menu when the user is not logged in.
 *
 * ```tsx
 *
 *   <UnauthorizedMenu />
 *
 * ```
 *
 * Function implementation
 *
 * ```tsx
 *
 * function UnauthorizedMenu(): React.JSX.Element {
 *  /...
 * }
 *
 * ```
 *  @returns {JSX.Element} The rendered component
 *
 * @Category Component
 */

function UnauthorizedMenu(props: any): React.JSX.Element {
  const { isMobile } = props;
  return (
    <>
      {/* boot */}
      <ul className={`navbar-nav me-auto navbar-nav-scroll${isMobile ? ' mobile_navigation' : ''}`}>
        <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <NavLink
            className="nav-link item d-flex gap-3 align-items-center"
            style={{ textDecoration: 'none', color: '#414448' }}
            to="/">
            <span className="menu_group">
              <i className="fal fa-home icon-mobile"></i>Home
            </span>
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textDecoration: 'none', color: '#414448' }}>
            <span className="menu_group">
              <i className="fal fa-clipboard-user icon-mobile"></i>Plan & <br></br> Invite
            </span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <h4 className="desktop_hide menu_header_title">Plan & Invite</h4>
            <li>
              <NavLink className=" dropdown-item" to="/save-the-date">
                <div className="title_mediumLight">Save The Date</div>
                <span>Seamless Invitation Creation </span>
              </NavLink>
            </li>
            <li>
              <NavLink className=" dropdown-item" to="/invitations">
                <div className="title_mediumLight">Invitations</div>
                <span>Seamless Invitation Creation </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/guests" className=" dropdown-item">
                <div className="title_mediumLight">Guest List</div>
                <span>Organize all the guests </span>
              </NavLink>
            </li>
            <li>
              <NavLink className=" dropdown-item" to="/events">
                <div className="title_mediumLight">Events</div>
                <span>Create and Manage your events </span>
              </NavLink>
            </li>
            <li>
              <NavLink className=" dropdown-item" to="/custom-design-form">
                <div className="title_mediumLight">Custom Invitations</div>
                <span>Create personalized design </span>
              </NavLink>
            </li>
          </ul>
          <div className="desktop_hide float_overflow"></div>
        </li>

        <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <NavLink
            className="item nav-link d-flex gap-3 align-items-center"
            style={{ textDecoration: 'none', color: '#414448' }}
            to="/video-invitations">
            <span className="menu_group">
              <i className="fal fa-photo-video icon-mobile"></i> Video <br></br>Invitation
            </span>
          </NavLink>
        </li>
        <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <NavLink
            className=" nav-link item d-flex gap-3 align-items-center"
            style={{ textDecoration: 'none', color: '#414448' }}
            to="/wedding-website">
            <span className="menu_group">
              <i className="fal fa-browser icon-mobile"></i> Wedding <br></br>Website
            </span>
          </NavLink>
        </li>
        <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <NavLink
            className="nav-link item d-flex gap-3 align-items-center"
            style={{ textDecoration: 'none', color: '#414448' }}
            to="/vendors">
            <span className="menu_group">
              <i className="fal fa-store icon-mobile"></i>Vendors
            </span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
export default UnauthorizedMenu;
