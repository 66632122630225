import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteUserTemlate,
  saveTheDateList,
  saveTheDateTemplateById,
  saveTheDateUserTemplate,
  saveTheDateUserTemplateById
} from '../service/save-the-date.service';

export const getSaveTheDateTemplateList = createAsyncThunk(
  'saveTheDateDesgin',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (eventGroupId: any) => {
    const response = await saveTheDateList(eventGroupId);
    return response.data;
  }
);

export const getSaveTheDateTemplateById = createAsyncThunk(
  'saveTheDateById',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (templateId: any) => {
    const response = await saveTheDateTemplateById(templateId);
    return response.data;
  }
);

export const addSaveTheDateUserTemplate = createAsyncThunk(
  'saveTheDateUserTemplate',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (body: any) => {
    const response = await saveTheDateUserTemplate(body);
    return response.data;
  }
);

export const deleteSaveTheDateListUserTemplate = createAsyncThunk(
  'deleteGuest',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: any) => {
    await deleteUserTemlate(data);
    return data;
  }
);

export const getSaveTheDateUsertemplateById = createAsyncThunk(
  'saveTheDateUserTemplateById',
  async (id: number) => {
    const response = await saveTheDateUserTemplateById(id);
    return response.data;
  }
);

interface SaveTheDateState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveTheDateTemplate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveTheDateTemplateById: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveTheDateList: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveTheDateUserTemplateById: any;
  loading: boolean;
  error: string | null;
}

const initialState: SaveTheDateState = {
  saveTheDateTemplate: [],
  saveTheDateTemplateById: {},
  saveTheDateList: [],
  saveTheDateUserTemplateById: [],
  loading: false,
  error: null
};

export const saveTheDateDesignSlice = createSlice({
  name: 'saveTheDateDesign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Save The Date Design Template
    builder.addCase(getSaveTheDateTemplateList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSaveTheDateTemplateList.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.saveTheDateList = data;
    });
    builder.addCase(getSaveTheDateTemplateList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Get Save The Date template by id
    builder.addCase(getSaveTheDateTemplateById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSaveTheDateTemplateById.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.saveTheDateTemplateById = data;
    });
    builder.addCase(getSaveTheDateTemplateById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //  Add Save The Date User teamplate
    builder.addCase(addSaveTheDateUserTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addSaveTheDateUserTemplate.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      console.log('Data', data);
    });
    builder.addCase(addSaveTheDateUserTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //  Get Save The Date User Template BY id
    builder.addCase(getSaveTheDateUsertemplateById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSaveTheDateUsertemplateById.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.saveTheDateUserTemplateById = data;
    });
    builder.addCase(getSaveTheDateUsertemplateById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //DELETE Guest
    builder.addCase(deleteSaveTheDateListUserTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSaveTheDateListUserTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.saveTheDateList = state.saveTheDateList.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item.id !== action.payload
      );
    });
    builder.addCase(deleteSaveTheDateListUserTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default saveTheDateDesignSlice.reducer;
