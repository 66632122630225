import httpService from '@viteplan/services/http.service';
import { Guest } from '../models/guest.model';

export const listGuests = (eventGroupId: number | undefined, config = {}) => {
  return httpService
    .get(`guest/group?eventGroupId=${eventGroupId}`, config)
    .then((res: Guest[]) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGuest = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`guest/group`, body, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteGuest = (config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.delete(`guest/groups`, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadGuestFile = (eventGroupId: number | undefined, body: any, config = {}) => {
  return (
    httpService
      .post(`guest/uploadExcel/${eventGroupId}`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const getGuestGroupTags = (eventGroupId: number | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`guest/tags/${eventGroupId}`).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateInvitedFor = (guestGroupId: number, body: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`guest/group/${guestGroupId}/invitedfor`, body).then((res: any) => res);
};

export const downloadRsvpExcelFile = (eventGroupId: number | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`guest/exportExcel/${eventGroupId}`).then((res: any) => res);
};

export const rsvpResponsesCount = (eventGroupId: number | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`event-group/${eventGroupId}/event-reports`).then((res: any) => res);
};

export const invitedGuestInEvent = (eventGroupId: number | undefined, eventId: number) => {
  return (
    httpService
      .get(`guest/group/${eventGroupId}/invitedFor/${eventId}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};
