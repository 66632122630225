import React, { useState } from 'react';
import { DRAWER_INNER_COMPONENTS, GlobalDrawerContext } from './GlobalDrawerContext';
import { DrawerContainer } from '../patterns/DrawerContainer/DrawerContainer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GlobalDrawerContextProvider(props: any) {
  const [store, setStore] = useState({
    drawerType: '',
    drawerProps: {},
    open: false
  });
  const { drawerType, drawerProps } = store || {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showDrawer = (drawerType: string, drawerProps: any, open = true) => {
    setStore({
      ...store,
      drawerType,
      drawerProps,
      open
    });
  };

  const hideDrawer = (open = false) => {
    setStore({
      ...store,
      drawerType: '',
      drawerProps: {},
      open
    });
  };

  const renderComponent = () => {
    const DrawerInnerComponent = DRAWER_INNER_COMPONENTS[drawerType];
    if (!drawerType || !DrawerInnerComponent) {
      return null;
    }
    return <DrawerInnerComponent {...drawerProps} />;
  };

  return (
    <>
      <GlobalDrawerContext.Provider value={{ store, showDrawer, hideDrawer }}>
        {props.children}
        <DrawerContainer config={store}>{renderComponent()}</DrawerContainer>
      </GlobalDrawerContext.Provider>
    </>
  );
}
