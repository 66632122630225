import eventHorizontal from '@viteplan/images/events-horizontal-align.png';
import eventVertical from '@viteplan/images/events-vertical-align.png';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { formatLocalizedDate, formatTimeTo12HourClock } from '@viteplan/util/date.util';
import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllEvents } from 'views/Events/slice/event-slice';
import ThreeDotsLoader from 'views/Loader/ThreeDotsLoader';
import {
  createColumnHtml,
  extractTextFromHtml,
  headerTags,
  parseHtmlStringToNode,
  updateColumnState,
  updatePageName
} from 'views/WeddingWebsite/service/website-helper.service';
import { imageupload } from 'views/WeddingWebsite/service/website-setting.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddEditContent = (props: any) => {
  const { eventGroup } = useAppSelector((state) => state.event);
  const dispatch = useAppDispatch();

  const {
    page,
    selectedItem,
    selectedIndex,
    updateWebsitePage,
    editLayout,
    existingPageName,
    parentSlug,
    addMoreItems,
    updatePageHeading,
    addCustomElement,
    insertInBetween
  } = props.data;

  const { auth } = useAuth();
  const { hideDrawer } = useGlobalDrawerContext();
  const [visibleDiv, setVisibleDiv] = useState<string | null>('Title');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedPage] = useState<any>(page);
  const [pageName, setPageName] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [paraGraphText, setParaGraphText] = useState<string | any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [titleText, setTitleText] = useState<string | any>();
  const [errorMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [titleTag, setTitleTag] = useState<string>('h1');
  const [leftColumn, setLeftColumn] = useState({
    title: '',
    paraGraph: '',
    image: '',
    className: 'left travel-box'
  });
  const [rightColumn, setRightColumn] = useState({
    title: '',
    paraGraph: '',
    image: '',
    className: 'right'
  });

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  useEffect(() => {
    if (!!selectedItem) {
      activateDiv(parseHtmlStringToNode(selectedItem));
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addEvents = (event: any, currentIndex: number) => {
    if (pageName === '' && page.name === '') return alert('Page Name is required');
    updatePageName(pageName, selectedPage, existingPageName);

    const clickedEventHtml = event.currentTarget.innerHTML;
    if (currentIndex !== null && currentIndex !== undefined) {
      selectedPage.items[currentIndex] = clickedEventHtml;
    } else {
      selectedPage.items.push(clickedEventHtml);
    }
    // Call the updateWebsitePage function with the updated selectedPage and optional parentSlug
    updateWebsitePage(selectedPage, parentSlug);
    hideDrawer();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderAddressInfo = (res: any) => {
    return (
      <p>{`${res?.address ? res.address + ', ' : ''}${res?.city ? res.city + ', ' : ''}${
        res?.state?.name ? res.state?.name + ', ' : ''
      }${res?.country?.name ? res.country.name + ' ' : ''}${res?.zip ? res.zip : ''}`}</p>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activateDiv = (content: string | any) => {
    if (typeof content === 'string') {
      setVisibleDiv(content);
    } else if (content) {
      const tagName = content.tagName.toLowerCase();
      if (
        tagName === 'h1' ||
        tagName === 'h2' ||
        tagName === 'h3' ||
        tagName === 'h4' ||
        tagName === 'h5' ||
        tagName === 'h6'
      ) {
        setTitleTag(tagName);
        setTitleText(extractTextFromHtml(selectedItem));
        console.log(extractTextFromHtml(selectedItem));

        setVisibleDiv('Title');
      } else if (tagName === 'p') {
        setParaGraphText(extractTextFromHtml(selectedItem));
        setVisibleDiv('Editor');
      } else if (tagName === 'img') {
        setVisibleDiv('Image');
      } else {
        const layoutData = extractTextFromHtml(selectedItem);
        setVisibleDiv('layout');
        if (layoutData.left) {
          updateColumnState(setLeftColumn, layoutData.left);
        }
        if (layoutData.right) {
          updateColumnState(setRightColumn, layoutData.right);
        }
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSave = (type: string, currentIndex: number, value = 'Update Text') => {
    if (pageName === '' && page.name === '') return alert('Page Name is required');
    updatePageName(pageName, selectedPage, existingPageName);
    if (insertInBetween) {
      selectedPage.items.splice(
        currentIndex,
        0,
        type === 'Title' ? `<${titleTag}>${value}</${titleTag}>` : `<p>${value}</p>`
      );
    }
    if (selectedItem) {
      if (type === 'Title' || type === 'Editor') {
        if (currentIndex !== null && currentIndex !== undefined) {
          if (selectedPage.items[currentIndex]) {
            selectedPage.items[currentIndex] =
              type === 'Title' ? `<${titleTag}>${value}</${titleTag}>` : `<p>${value}</p>`;
          } else {
            selectedPage.items.push(
              type === 'Title' ? `<${titleTag}>${value}</${titleTag}>` : `<p>${value}</p>`
            );
          }
        }
      }
    }
    if (addMoreItems || addCustomElement) {
      selectedPage.items.push(
        type === 'Title' ? `<${titleTag}>${value}</${titleTag}>` : `<p>${value}</p>`
      );
    }
    // Call the updateWebsitePage function with the updated selectedPage and optional parentSlug
    updateWebsitePage(selectedPage, parentSlug);
    hideDrawer();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSingleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (pageName === '' && page.name === '') return alert('Page Name is required');
    const selectedFile = event.target.files && event.target.files[0];
    console.log('Selected File handle image change', selectedFile);
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append('files', selectedFile);
      try {
        const response = await imageupload(userEventGroup?.id, formData);
        if (response.data.data) {
          toast.success('Image uploaded successfully', {
            position: toast.POSITION.TOP_RIGHT
          });
          // Construct the image HTML
          const imgHtml = `<img class="couple_banner" src=${response.data.data[0]} alt=""/>`;
          if (insertInBetween) {
            selectedPage.items.splice(index, 0, imgHtml);
          }
          // Check if there is an item at the specified index
          if (index >= 0 && index < selectedPage.items.length) {
            // Replace the item at the specified index with the new image HTML
            selectedPage.items[index] = imgHtml;
          }
          if (selectedPage.name && addMoreItems) {
            selectedPage.items.push(imgHtml);
            console.log(selectedPage);
          } else {
            if (pageName.trim() !== '') {
              const originalPageName = selectedPage.name; // Store the original name
              const newPageName = pageName.trim();
              // Check if the new page name already exists in websitePage
              if (existingPageName.includes(newPageName)) {
                alert('Page already exists.');
                return;
              }
              selectedPage.name = newPageName;
              // Check if the slug and path need to be updated
              if (originalPageName !== newPageName) {
                const newPageSlug = newPageName.toLowerCase().replace(/\s+/g, '-');
                const newPagePath = `/${newPageSlug}`;

                selectedPage.slug = newPageSlug;
                selectedPage.path = newPagePath;
              }
              selectedPage.items.push(imgHtml);
            }
          }
          // Call the updateWebsitePage function with the updated selectedPage
          updateWebsitePage(selectedPage, parentSlug);
          hideDrawer();
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    }

    event.target.value = '';
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    editLayout(event.target.value);
    hideDrawer();
  };

  const addLayoutToPage = (currentIndex: number) => {
    if (pageName === '' && page.name === '') return alert('Page Name is required');
    if (pageName.trim() !== '') {
      const originalPageName = selectedPage.name; // Store the original name
      const newPageName = pageName.trim();

      // Check if the new page name already exists in websitePage
      if (existingPageName.includes(newPageName)) {
        alert('Page already exists.');
        return;
      }
      selectedPage.name = newPageName;

      // Check if the slug and path need to be updated
      if (originalPageName !== newPageName) {
        const newPageSlug = newPageName.toLowerCase().replace(/\s+/g, '-');
        const newPagePath = `/${newPageSlug}`;

        selectedPage.slug = newPageSlug;
        selectedPage.path = newPagePath;
      }
    }
    const hasLeftContent = leftColumn.title || leftColumn.paraGraph || leftColumn.image;
    const hasRightContent = rightColumn.title || rightColumn.paraGraph || rightColumn.image;

    let layoutHtml =
      '<div class="left-right-wrap d-flex mt-5 gap-5 flex-column flex-sm-row justify-content-around">';
    if (hasLeftContent) {
      layoutHtml += createColumnHtml(leftColumn);
    }
    if (hasRightContent) {
      layoutHtml += createColumnHtml(rightColumn);
    }
    layoutHtml += '</div>';

    if (insertInBetween) {
      selectedPage.items.splice(currentIndex, 0, layoutHtml);
    }
    if (currentIndex >= 0 && currentIndex < selectedPage.items.length) {
      selectedPage.items[currentIndex] = layoutHtml;
    } else {
      selectedPage.items.push(layoutHtml);
    }

    updateWebsitePage(selectedPage, parentSlug);
    hideDrawer();
  };

  const handleLayoutImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    column: string,
    currentIndex: number
  ) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append('files', selectedFile);
      try {
        const response = await imageupload(userEventGroup?.id, formData);
        if (response.data.data) {
          const imgHtml = `<img src=${response.data.data[0]} alt=""/>`;
          if (column === 'left') {
            setLeftColumn((prevLeftColumn) => ({
              ...prevLeftColumn,
              image: imgHtml
            }));
          }
          if (column === 'right') {
            setRightColumn((prevRightColumn) => ({
              ...prevRightColumn,
              image: imgHtml
            }));
          }
          console.log(column, currentIndex, leftColumn.image, rightColumn.image);
        }
      } catch (e) {
        console.log('Error occured', e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllEvents(userEventGroup?.id));
  }, []);

  if (page.name !== 'Events') {
    return (
      <>
        <section id="website_popover_panel" className="model_popover_panel ">
          <div className="page_name">
            <div className="content_panel">
              <div className="block_title">
                <label>Page Name</label>
                <input
                  disabled={addMoreItems}
                  type="text"
                  defaultValue={page?.name || ''}
                  name={page?.name}
                  onChange={(event) => {
                    setPageName(event.target.value);
                  }}
                />
              </div>
            </div>
            {updatePageHeading && (
              <button
                className="mt-3 btn primary"
                onClick={() => handleSave('', selectedIndex, '')}>
                {' '}
                Save
              </button>
            )}
          </div>
          {!updatePageHeading && (
            <div className="brd-line">
              <div className="popover_body">
                <div className="pop-up_pannel">
                  <div className="add-to-page">
                    <div className="content_panel">
                      <div className="block_elements">
                        <div
                          id="title-block"
                          className={visibleDiv === 'Title' ? 'active-box' : ''}
                          onClick={() => activateDiv('Title')}
                          style={{ position: 'relative', overflow: 'hidden' }}>
                          <input
                            type="radio"
                            name="check"
                            className="title_show position-absolute w-100 h-100"
                            style={{ opacity: '0', top: '0', left: '0' }}
                          />
                          <i className="far fa-text"></i>
                          <span>Title</span>
                        </div>

                        <div
                          onClick={() => activateDiv('Editor')}
                          className={visibleDiv === 'Editor' ? 'active-box' : ''}
                          style={{ position: 'relative', overflow: 'hidden' }}>
                          <input
                            type="radio"
                            name="check"
                            className="text_show position-absolute w-100 h-100"
                            style={{ opacity: '0', top: '0', left: '0' }}
                          />
                          <i className="fas fa-align-left"></i>
                          <span>Text Block</span>
                        </div>

                        <div
                          onClick={() => activateDiv('Image')}
                          className={visibleDiv === 'Image' ? 'active-box' : ''}
                          style={{ position: 'relative', overflow: 'hidden' }}>
                          <input
                            type="radio"
                            name="check"
                            className="image_show position-absolute w-100 h-100"
                            style={{ opacity: '0', top: '0', left: '0' }}
                          />
                          <i className="far fa-image"></i>
                          <span>Image</span>
                        </div>
                        <div
                          onClick={() => activateDiv('layout')}
                          className={visibleDiv === 'layout' ? 'active-box' : ''}
                          style={{ position: 'relative', overflow: 'hidden' }}>
                          <input
                            type="radio"
                            name="check"
                            className="image_show position-absolute w-100 h-100"
                            style={{ opacity: '0', top: '0', left: '0' }}
                          />
                          <i className="far fa-border-all"></i>
                          <span>Layout</span>
                        </div>
                        {/* <div
                          onClick={() => activateDiv('Add Event')}
                          className={visibleDiv === 'Add Event' ? 'active-box' : ''}
                          style={{ position: 'relative', overflow: 'hidden' }}>
                          <input
                            type="radio"
                            name="check"
                            className="image_show position-absolute w-100 h-100"
                            style={{ opacity: '0', top: '0', left: '0' }}
                          />
                          <i className="far fa-border-all"></i>
                          <span>Add Events</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {visibleDiv === 'Title' && (
                    <div className="add-title mt-3">
                      {headerTags.map((tag) => (
                        <button
                          key={tag}
                          className={titleTag === tag ? 'active-button' : ''}
                          onClick={() => setTitleTag(tag)}>
                          {tag}
                        </button>
                      ))}
                      <header className="mt-2">
                        <h1 className="title_mediumLight">Add Title</h1>
                      </header>

                      <div className="content_panel">
                        <div className="block_title mt-1 ">
                          <input
                            type="text"
                            placeholder="Title"
                            value={titleText || ''}
                            onChange={(event) => setTitleText(event.target.value)}
                          />
                          {errorMessage && <div className="field_error">{errorMessage}</div>}
                          <div className="btn-box">
                            <button
                              className="btn primary"
                              type="button"
                              onClick={() => handleSave('Title', selectedIndex, titleText)}>
                              Add to Page
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {visibleDiv === 'Editor' && (
                    <div className="add-text">
                      <header>
                        <h1 className="title_mediumLight">Add Text</h1>
                      </header>
                      <section>
                        <div className="content_panel">
                          <div className="block_title">
                            <textarea
                              rows={10}
                              value={paraGraphText || ''}
                              onChange={(event) => setParaGraphText(event.target.value)}></textarea>
                            {errorMessage && <div className="field_error">{errorMessage}</div>}
                            <div className="btn-box">
                              <button
                                className="btn primary"
                                type="button"
                                onClick={() => handleSave('Editor', selectedIndex, paraGraphText)}>
                                Add to Page
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                  {visibleDiv === 'Image' && (
                    <div className="add-image">
                      <header>
                        <h1 className="title_mediumLight">Add Image</h1>
                      </header>
                      <div className="content_panel">
                        <div className="accordion-body">
                          <div className="upload_img popup-form">
                            <div className="border_dotted">
                              {loading ? (
                                <ThreeDotsLoader />
                              ) : selectedItem && selectedItem.startsWith('<img') ? (
                                <span
                                  style={{ width: '50px' }}
                                  dangerouslySetInnerHTML={{ __html: selectedItem }}></span>
                              ) : (
                                <span>
                                  <i className="far fa-images"></i>
                                </span>
                              )}
                              <span>
                                <form
                                  action="#type your action here"
                                  method="POST"
                                  encType="multipart/form-data"
                                  style={{ position: 'relative' }}
                                  name="myForm">
                                  <label htmlFor="image" className="btn primary" id="yourBtn">
                                    Add Images  {' '}
                                  </label>
                                  <input
                                    id="image"
                                    type="file"
                                    style={{
                                      position: 'absolute',
                                      top: '0',
                                      left: '0',
                                      opacity: '0',
                                      height: '100%'
                                    }}
                                    onChange={(event) =>
                                      handleSingleImageChange(event, selectedIndex)
                                    }
                                  />
                                </form>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {visibleDiv === 'layout' && (
                    <>
                      <div className="layout_radio">
                        <div className="radio-botton">
                          <input
                            type="radio"
                            id="2-columns"
                            name="Columns"
                            value="2-Columns"
                            checked
                          />
                          <label htmlFor="2-columns">2 Columns</label>
                        </div>
                        {/* <div className="radio-botton">
                          <input type="radio" id="3-columns" name="Columns" value="3-Columns" />
                          <label htmlFor="3-columns">3 Columns</label>
                        </div> */}
                      </div>
                      <div className="layout_main_div">
                        <div className="layout_wrapper">
                          <div className="layout_row">
                            <div className="layout_column">
                              <span className="label_text">
                                <i className="fas fa-heading"></i> Add Heading
                              </span>
                              <input
                                type="text"
                                value={leftColumn.title || ''}
                                onChange={(event) =>
                                  setLeftColumn((prevLeftColumn) => ({
                                    ...prevLeftColumn,
                                    title: event.target.value
                                  }))
                                }
                              />
                            </div>
                            <div className="layout_column">
                              <span className="label_text">
                                <i className="far fa-image"></i> Add Image
                              </span>
                              <div className="content_panel">
                                <div className="accordion-body">
                                  <div className="upload_img popup-form">
                                    <div className="border_dotted">
                                      {leftColumn.image ? (
                                        <span
                                          style={{ width: '50px' }}
                                          dangerouslySetInnerHTML={{
                                            __html: leftColumn.image
                                          }}></span>
                                      ) : (
                                        <span>
                                          <i className="far fa-images"></i>
                                        </span>
                                      )}
                                      <span>
                                        <form
                                          action="#type your action here"
                                          method="POST"
                                          encType="multipart/form-data"
                                          name="myForm">
                                          <label
                                            htmlFor="imageLeft"
                                            className="btn primary"
                                            id="yourBtn">
                                            Add Image  {' '}
                                          </label>
                                          <div
                                            style={{
                                              height: '0px',
                                              width: '0px',
                                              overflow: 'hidden'
                                            }}>
                                            <input
                                              id="imageLeft"
                                              type="file"
                                              onChange={(event) =>
                                                handleLayoutImageChange(
                                                  event,
                                                  'left',
                                                  selectedIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </form>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="layout_column">
                              <span className="label_text">
                                <i className="fas fa-paragraph"></i> Add Paragraph
                              </span>
                              <div className="content_panel">
                                <div className="block_title my-0">
                                  <textarea
                                    rows={10}
                                    value={leftColumn.paraGraph || ''}
                                    onChange={(event) =>
                                      setLeftColumn((prevLeftColumn) => ({
                                        ...prevLeftColumn,
                                        paraGraph: event.target.value
                                      }))
                                    }></textarea>
                                  {errorMessage && (
                                    <div className="field_error">{errorMessage}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="layout_row">
                            <div className="layout_column">
                              <span className="label_text">
                                <i className="fas fa-heading"></i> Add Heading
                              </span>
                              <input
                                type="text"
                                value={rightColumn.title || ''}
                                onChange={(event) =>
                                  setRightColumn((prevLeftColumn) => ({
                                    ...prevLeftColumn,
                                    title: event.target.value
                                  }))
                                }
                              />
                            </div>
                            <div className="layout_column">
                              <span className="label_text">
                                <i className="far fa-image"></i> Add Image
                              </span>
                              <div className="content_panel">
                                <div className="accordion-body">
                                  <div className="upload_img popup-form">
                                    <div className="border_dotted">
                                      {rightColumn.image ? (
                                        <span
                                          style={{ width: '50px' }}
                                          dangerouslySetInnerHTML={{
                                            __html: rightColumn.image
                                          }}></span>
                                      ) : (
                                        <span>
                                          <i className="far fa-images"></i>
                                        </span>
                                      )}
                                      <span>
                                        <form
                                          action="#type your action here"
                                          method="POST"
                                          encType="multipart/form-data"
                                          name="myForm">
                                          <label
                                            htmlFor="imageRight"
                                            className="btn primary"
                                            id="yourBtn">
                                            Add Image  {' '}
                                          </label>
                                          <div
                                            style={{
                                              height: '0px',
                                              width: '0px',
                                              overflow: 'hidden'
                                            }}>
                                            <input
                                              id="imageRight"
                                              type="file"
                                              onChange={(event) =>
                                                handleLayoutImageChange(
                                                  event,
                                                  'right',
                                                  selectedIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </form>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="layout_column">
                              <span className="label_text">
                                <i className="fas fa-paragraph"></i> Add Paragraph
                              </span>
                              <div className="content_panel">
                                <div className="block_title my-0">
                                  <textarea
                                    rows={10}
                                    value={rightColumn.paraGraph || ''}
                                    onChange={(event) =>
                                      setRightColumn((prevLeftColumn) => ({
                                        ...prevLeftColumn,
                                        paraGraph: event.target.value
                                      }))
                                    }></textarea>
                                  {errorMessage && (
                                    <div className="field_error">{errorMessage}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="btn-box mt-5 text-center">
                          <button
                            className="btn primary"
                            type="button"
                            onClick={() => addLayoutToPage(selectedIndex)}>
                            Add to Page
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {visibleDiv === 'Add Event' && (
                    <div className="more_itm model_popup_action">
                      <div className={`event_container`}>
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {eventGroup?.events?.map((res: any, index: number) => {
                          return (
                            <div onClick={(e) => addEvents(e, selectedIndex)}>
                              <div className={`event_container`}>
                                <div className="event_card" key={index}>
                                  <h3>{res?.name}</h3>
                                  <p>{res?.date ? formatLocalizedDate(res?.date) : ''}</p>
                                  <p>
                                    {res?.startTime ? formatTimeTo12HourClock(res?.startTime) : ''}
                                    {res?.startTime &&
                                      res?.endTime &&
                                      ` - ${formatTimeTo12HourClock(res?.endTime)}`}
                                    {!res?.endTime && res?.startTime && ' onwards'}
                                  </p>
                                  <h6> {res?.venue === null ? '' : <p>{res?.venue}</p>} </h6>
                                  {renderAddressInfo(res)}
                                  <div className="devider"></div>
                                  <div className="saprator-divider mb-1"></div>
                                  {res?.hasSchedule &&
                                    res?.eventSchedules &&
                                    res.eventSchedules.length > 0 && (
                                      <>
                                        <h5 className="event_column">Event Schedule</h5>
                                        <table className="table table-borderless">
                                          <tbody>
                                            {res?.eventSchedules.map(
                                              /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
                                              (schedule: any, scheduleIndex: number) => (
                                                <tr key={scheduleIndex}>
                                                  <th scope="row">{schedule.name}</th>
                                                  <td>
                                                    {schedule?.endTime
                                                      ? `${formatTimeTo12HourClock(
                                                          schedule.startTime
                                                        )} - ${formatTimeTo12HourClock(
                                                          schedule.endTime
                                                        )}`
                                                      : `${formatTimeTo12HourClock(
                                                          schedule.startTime
                                                        )} onwards`}
                                                  </td>
                                                  <td>{schedule?.note}</td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </>
                                    )}
                                  {res?.hasNotes && res?.notes && (
                                    <>
                                      <h5 className="event_column">Notes</h5>
                                      <p className="guest-note">{res?.notes}</p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
      </>
    );
  }
  if (page.name === 'Events') {
    return (
      <div className="vertical_layout">
        <div className="layout_wrap ">
          <div className="radiobox">
            <input
              type="radio"
              id="Vertical"
              name="layout"
              value="vertical"
              onChange={handleRadioChange}
            />
            <label htmlFor="Vertical">Vertical Layout</label>
          </div>
          <img src={eventVertical} alt="" height={250} width={300} />
        </div>
        <div className="layout_wrap ">
          <div className="radiobox">
            <input
              type="radio"
              id="Horizontal"
              name="layout"
              value="horizontal"
              onChange={handleRadioChange}
            />
            <label htmlFor="Horizontal">Horizontal Layout</label>
          </div>
          <img src={eventHorizontal} alt="" height={250} width={300} />
        </div>
      </div>
    );
  }
};

export default AddEditContent;
