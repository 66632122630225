/* eslint-disable @typescript-eslint/no-explicit-any */
export const extractHtmlElements = (htmlArray: string[]) => {
  const htmlElements: string[] = [];

  htmlArray.forEach((html) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');

    const containerDiv = parsedHtml.querySelector('.container');

    if (containerDiv) {
      containerDiv.childNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;

          // Remove backslashes from the outerHTML of the element
          const cleanedHtml = element.outerHTML.replace(/\\/g, '');

          // Extract inner text
          const innerText = element.innerText;

          // Replace only the inner text part with the extracted inner text
          const finalHtml = cleanedHtml.replace(element.innerText, innerText);

          htmlElements.push(finalHtml);
        }
      });
    }
  });

  return htmlElements;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const htmlContentForWebsite = (pageName: string, modifiedHtml: string) => {
  switch (pageName) {
    case 'HOME':
      return `
        <div class="container">
           ${modifiedHtml}
        </div>`;

    case 'OUR_STORY':
      return `
          <div class="container">
             ${modifiedHtml}
          </div>`;
  }
};

export const parseHtmlStringToNode = (htmlString: string) => {
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(htmlString, 'text/html');

  const firstElementInBody = parsedDocument.querySelector('body > *');
  if (firstElementInBody?.className === 'event_container') return 'Add Event';
  if (firstElementInBody) {
    return firstElementInBody;
  } else {
    return null;
  }
};

export const extractTextFromHtml = (htmlString: string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const firstChild = tempElement.firstChild;
  if (firstChild && firstChild.nodeName.toLowerCase() === 'div') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any = { left: [], right: [] };
    // const content = [{ tagName: '', textContent: '' }];
    firstChild.childNodes.forEach((child) => {
      if (child.nodeType === 1 && (child as Element).classList) {
        const tagName = child.nodeName.toLowerCase();

        // Create separate arrays for "left" and "right"
        const leftContent: any = [];
        const rightContent: any = [];

        // Iterate through child nodes
        child.childNodes.forEach((item: any) => {
          const itemTagName = item.nodeName.toLowerCase();
          const itemTextContent = item.textContent;

          // Push data to the appropriate array based on class
          if ((child as Element).classList.contains('left')) {
            leftContent.push({ tagName: itemTagName, textContent: itemTextContent, src: item.src });
          } else if ((child as Element).classList.contains('right')) {
            rightContent.push({
              tagName: itemTagName,
              textContent: itemTextContent,
              src: item.src
            });
          }
        });

        // Check if the class is "left" or "right" and push content accordingly
        const columnClass = (child as Element).classList.contains('left') ? 'left' : 'right';
        columns[columnClass].push({
          tagName,
          content: columnClass === 'left' ? leftContent : rightContent,
          className: columnClass
        });
      }
    });

    tempElement.remove();

    return columns;
  }

  const textContent = tempElement.textContent;
  tempElement.remove();

  return textContent;
};

export const updateColumnState = (columnSetter: any, data: any) => {
  data.forEach((item: any) => {
    item.content.forEach((contentData: any) => {
      switch (contentData.tagName) {
        case 'h1':
          columnSetter((prevColumn: any) => ({
            ...prevColumn,
            title: contentData.textContent
          }));
          break;
        case 'p':
          columnSetter((prevColumn: any) => ({
            ...prevColumn,
            paraGraph: contentData.textContent
          }));
          break;
        case 'img':
          columnSetter((prevColumn: any) => ({
            ...prevColumn,
            image: `<img src=${contentData.src} alt=""/>`
          }));
          break;
      }
    });
  });
};

export const createColumnHtml = (column: any) => {
  let columnHtml = `<div class="${column.className}">`;
  if (column.title) {
    columnHtml += `<h1>${column.title}</h1>`;
  }
  if (column.image) {
    columnHtml += `${column.image}`;
  }
  if (column.paraGraph) {
    columnHtml += `<p>${column.paraGraph}</p>`;
  }
  columnHtml += '</div>';
  return columnHtml;
};

export const updatePageName = (pageName: string, selectedPage: any, existingPageName: any) => {
  if (pageName.trim() !== '') {
    const originalPageName = selectedPage.name; // Store the original name
    const newPageName = pageName.trim();

    // Check if the new page name already exists in existingPageName
    if (existingPageName.includes(newPageName)) {
      alert('Page already exists.');
      return;
    }

    selectedPage.name = newPageName;

    // Check if the slug and path need to be updated
    if (originalPageName !== newPageName) {
      const newPageSlug = newPageName.toLowerCase().replace(/\s+/g, '-');
      const newPagePath = `/${newPageSlug}`;

      selectedPage.slug = newPageSlug;
      selectedPage.path = newPagePath;
    }
  }
};

export const headerTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
