import React from 'react';
import Select from 'react-select';
/**
 * @memberof DropDown
 */
/**
 * The props type for {@link DropDown}.
 *
 * The props type is defined as a separate interface
 *
 * ```tsx
 * export interface IDropDown {
 *   //...
 *  }
 * ```
 * @interface
 */
export interface IDropDown {
  /** The id of the DropDown. */
  id?: string;

  /** The link of the button. */
  name?: string;

  /** The onChange handler function.*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;

  /** The options of the DropDown.*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;

  /** The Selected options for the DropDown. Defaults to `null`.*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions?: any;

  /** For the multiselect dropdown */
  isMultiSelect?: boolean;

  /** For displaying custom message when there is no data */
  message?: string;

  /**Use to disabled Dropdown */
  disabled?: boolean;

  offSet?: number;
}
/**
 * Renders a Select2 DropDown in the Form.
 *
 * ```tsx
 *
 *  <DropDown id={id} name={name} options={[options]} onChange={onChangeHandler} selectedOptions={selectedOption} />
 *
 * ```
 *
 * Function implementation
 *
 * ```tsx
 *
 * export function DropDown(props: any) {
 *  //...
 *  }
 * ```
 *
 * @Category Component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DropDown({ ...props }: IDropDown): React.JSX.Element {
  const {
    id,
    selectedOptions,
    onChange,
    options,
    isMultiSelect = false,
    message = 'No Options',
    disabled,
    offSet = 1
  } = props;

  const defaultValue = selectedOptions
    ? Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => ({ label: option.name, value: option.id }))
      : { label: selectedOptions.name, value: selectedOptions.id }
    : null;

  const menuPlacement = offSet < 100 ? 'bottom' : 'top';

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (provided: any, state: any) => {
      return {
        ...provided,
        zIndex: 9999,
        fontFamily: state.data.fontFamily || 'inherit'
      };
    }
  };

  return (
    <Select
      isDisabled={disabled}
      id={id}
      onChange={onChange}
      options={options}
      className="select-dropdown-container with_input_field"
      classNamePrefix="select_dropdown"
      defaultValue={defaultValue}
      isMulti={isMultiSelect}
      noOptionsMessage={() => message}
      styles={customStyles}
      menuPlacement={menuPlacement}
    />
  );
}
