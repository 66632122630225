import httpService from '@viteplan/services/http.service';
import { AxiosResponse } from 'axios';

export const videolist = (pageNo: number, pageSize: number) => {
  return httpService
    .get(`video?pageNo=${pageNo}&pageSize=${pageSize}`)
    .then((res: AxiosResponse) => res);
};

export const videoById = (videoId: number) => {
  return httpService.get(`video/${videoId}`).then((res: AxiosResponse) => res);
};

export const videoByUrl = (urlText: string) => {
  return httpService.get(`/video/byUrl?urlText=${urlText}`).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const videoInvitationRequest = (body: any, config = {}) => {
  return httpService
    .post(`video-invitation-request`, body, config)
    .then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const videoInvitationEvents = (videoId: number, body: any) => {
  return httpService
    .post(`video-invitation-request/${videoId}/events`, body)
    .then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resendOTP = (body: any) => {
  return httpService.post(`resend-email-OTP`, body).then((res: AxiosResponse) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const verifyUserOTP = (body: any) => {
  return httpService.post(`verify-user-otp`, body).then((res: AxiosResponse) => res);
};

export const getVideoInvitationById = (videoReqId: number) => {
  return httpService
    .get(`video-invitation-request/${videoReqId}`)
    .then((res: AxiosResponse) => res);
};

export const createOrderForVideoReq = (videoReqId: number) => {
  return httpService.get(`order/videoReq/${videoReqId}/create`).then((res: AxiosResponse) => res);
};

export const videoInvitationOrderDetails = (orderId: number) => {
  return httpService.get(`order/get/${orderId}`).then((res: AxiosResponse) => res);
};

export const applyOrderCouponCode = (orderId: number, couponCode: string) => {
  return httpService
    .get(`order/applyDiscount/${orderId}/${couponCode}`)
    .then((res: AxiosResponse) => res);
};

export const orderByUserId = (userId: number, pageNo: number, pageSize: number) => {
  return httpService
    .get(`order/user/${userId}?pageNo=${pageNo}&pageSize=${pageSize}`)
    .then((res: AxiosResponse) => res);
};
