import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { DropDown } from '@viteplan/components/common/DropDown';
import { useGlobalDrawerContext } from '../../../context-api/GlobalDrawerContext';
import { useAppDispatch } from '../../../hooks/hooks';
import { mapDataToDropDown } from '@viteplan/util/dropdown.util';
import { Event } from '../models/event.model';
import { getCountries, getStates } from '@viteplan/services/master-data.service';
import { eventTypes } from '../services/event.service';
import { addEventData, updateEventData } from '../slice/event-slice';
import * as Yup from 'yup';
import { EventType } from '../models/event-type.model';
import { mapDataToEventForm } from '../services/event-helper.service';
import useAuth from '../../../hooks/useAuth';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AddEditEvent(props: any) {
  const { auth } = useAuth();

  const { drawerOpenState = true, setDrawerOpenState, editPanel, data } = props;
  const dispatch = useAppDispatch();
  const [eventType, setEventType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(1);
  const [hasSchedule, setHasSchedule] = useState(false);

  const { hideDrawer } = useGlobalDrawerContext();

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  const initEventTypes = async () => {
    const response = await eventTypes();
    setEventType(response.data);
  };

  const initCountries = async () => {
    const response = await getCountries();
    setCountries(response.data);
  };

  const initStates = async () => {
    if (selectedCountryId) {
      const response = await getStates(selectedCountryId);
      setStates(response.data);
    }
  };

  // Event Type
  useEffect(() => {
    initEventTypes();
  }, []);

  //Country
  useEffect(() => {
    initCountries();
  }, []);

  //State
  useEffect(() => {
    initStates();
    if (data) {
      setSelectedCountryId(data?.country?.id || selectedCountryId);
    } else {
      setSelectedCountryId(selectedCountryId);
    }
  }, [selectedCountryId]);

  const eventValidationSchema = Yup.object().shape({
    eventType: new Yup.ObjectSchema().shape({
      name: Yup.string().required('Event Type is Required')
    }),
    date: Yup.string().required('Event Date is required'),
    startTime: Yup.string().required('Start Time is required'),
    name: Yup.string().required('Event name is required'),
    venue: Yup.string().required('Venue is required'),
    country: Yup.object()
      .shape({
        name: Yup.string().required('Country is Required')
      })
      .required('Country is Required'),

    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City name is required'),
    zip: Yup.string().required('Zip code is required')
  });

  const eventFormState: Event = {
    eventType: {} as EventType,
    name: '',
    date: '',
    startTime: '',
    endTime: '',
    venue: '',
    address: '',
    floor: '',
    city: '',
    zip: '',
    notes: '',
    isDefault: false,
    hasMeal: false,
    hasSchedule: false,
    hasNotes: false,
    guestsAllowedToAddGuest: false,
    noOfGuestsAllowed: null,
    country: { id: 0, name: '' },
    meals: [
      {
        name: '',
        isveg: true,
        id: '',
        description: '',
        createdOn: '',
        lastUpdatedOn: ''
      }
    ],
    eventSchedules: [
      {
        name: '',
        startTime: '',
        endTime: '',
        note: ''
      }
    ],
    isPublic: false,
    isRSVPRequired: true,
    isRSVPRestricted: false
  };
  return (
    <Formik
      // Prepare Data as Required in the form
      initialValues={editPanel ? mapDataToEventForm(data) : eventFormState}
      validationSchema={eventValidationSchema}
      onSubmit={(values) => {
        if (editPanel) {
          const request = {
            id: data.id,
            isDefault: data.isDefault,
            ...values
          };
          // we can dispatch get all event as well currently updating state in slice
          dispatch(updateEventData({ eventGroupId: userEventGroup?.id, data: request }));
        } else {
          dispatch(
            addEventData({
              eventGroupId: userEventGroup?.id,
              data: values
            })
          );
        }
        if (!drawerOpenState) setDrawerOpenState();
        if (drawerOpenState) hideDrawer();
      }}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="content_panel gap-4 d-flex flex-column">
            <div className="group_field_container gap-4">
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <label className="field_title">Event Type*</label>
                  <DropDown
                    id="eventType"
                    name="eventType"
                    offSet={13}
                    onChange={(option: { value: number; label: string }) => {
                      setFieldValue('eventType', { id: option.value, name: option.label });
                    }}
                    options={mapDataToDropDown(eventType)}
                    selectedOptions={values.eventType}
                  />
                  {errors.eventType && touched.eventType ? (
                    <span className="field_error">Event type is required</span>
                  ) : null}
                  <ErrorMessage className="field_error" name={`eventType.name`} component="span" />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">Event Name*</label>
                  <input
                    name="name"
                    type="text"
                    value={values.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage className="field_error" name={`name`} component="span" />
                </span>
              </div>
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <span>
                    <label className="field_title">Event Date*</label>{' '}
                    <span style={{ fontSize: '12px', fontWeight: '100' }}>{`(mm-dd-yyyy)`}</span>
                  </span>
                  <input
                    name="date"
                    type="date"
                    value={values.date || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage className="field_error" name={`date`} component="span" />
                </span>
                <span className=" d-flex w-100 w-sm-50 position-relative flex-wrap">
                  <span className=" d-flex w-100  gap-3">
                    <span className="field_container w-50">
                      <label className="field_title">Start Time*</label>
                      <input
                        name="startTime"
                        type="time"
                        value={values.startTime || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="field_error" name={`startTime`} component="span" />
                    </span>
                    <span className="field_container w-50">
                      <label className="field_title">End Time*</label>
                      <input
                        name="endTime"
                        type="time"
                        value={values.endTime || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="field_error" name={`endTime`} component="span" />
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div className="group_field_container gap-4">
              <h1 className="title_small form_group_title">Location</h1>
              <span className="field_container">
                <label className="field_title">Venue*</label>
                <input
                  name="venue"
                  type="text"
                  value={values.venue || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage className="field_error" name={`venue`} component="span" />
              </span>
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <label className="field_title">Address*</label>
                  <input
                    name="address"
                    type="text"
                    value={values.address || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />{' '}
                  <ErrorMessage className="field_error" name={`address`} component="span" />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">Apt/Floor</label>
                  <input
                    name="floor"
                    type="text"
                    value={values.floor || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </span>
              </div>
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <label className="field_title">Country*</label>
                  <DropDown
                    id="countryId"
                    onChange={(option: { value: number; label: string }) => {
                      console.log('AAAAAAA', option.value, option.label);
                      setFieldValue('country', { id: option.value, name: option.label });
                      setSelectedCountryId(option.value);
                    }}
                    options={mapDataToDropDown(countries)}
                    selectedOptions={values.country}
                  />
                  <ErrorMessage className="field_error" name={`country.name`} component="span" />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">State</label>
                  <DropDown
                    id="stateId"
                    name="state"
                    onChange={(option: { value: number; label: string }) => {
                      setFieldValue('state', { id: option.value, name: option.label });
                    }}
                    options={mapDataToDropDown(states)}
                    selectedOptions={values?.state}
                  />
                  <ErrorMessage className="field_error" name={`state.name`} component="span" />
                </span>
              </div>
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <label className="field_title">City*</label>
                  <input
                    name="city"
                    type="text"
                    value={values.city || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage className="field_error" name={`city`} component="span" />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">Zip*</label>
                  <input
                    name="zip"
                    type="text"
                    value={values.zip || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage className="field_error" name={`zip`} component="span" />
                </span>
              </div>
            </div>
            <div className="group_field_container">
              <h1 className="title_small form_group_title mb-0">Additional Setting</h1>
              <h1 className="title_toggle_panel">
                <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between' }}>
                  <span className="title_mediumLight box_size ">RSVP Required</span>
                  <span className="check-box small">
                    <Field
                      type="checkbox"
                      name="isRSVPRequired"
                      onClick={() => {
                        values.isRSVPRequired = !values.isRSVPRequired;
                      }}
                    />
                  </span>
                </div>
                <span className="info_tooltip_panel">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tooltip-id="rsvp_required_info"></i>
                  <ReactTooltip
                    id="rsvp_required_info"
                    place="bottom"
                    content="Enable this if you want the RSVP from guest for this event"
                  />
                </span>
              </h1>
              {values.isRSVPRequired ? (
                <div className="additional_setting_toggle_child">
                  <span className="field_container w-50 py-1">
                    <label className="field_title">RSVP end Date</label>
                    <input
                      type="date"
                      name="rsvpEndDate"
                      value={values.rsvpEndDate || values.date}
                      style={{ height: '36px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </span>
                  <span className="field_container w-50 py-1">
                    <label className="field_title">
                      RSVP Restricted{' '}
                      <span className="info_tooltip_panel">
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tooltip-id="rsvp_restricted_info"></i>
                        <ReactTooltip id="rsvp_restricted_info">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span> If you select this option,</span>
                            <span> You have to select the invitees name to this event.</span>
                          </div>
                        </ReactTooltip>
                      </span>
                    </label>

                    <span className="check-box small">
                      <Field
                        type="checkbox"
                        name="isRSVPRestricted"
                        onClick={() => {
                          values.isRSVPRestricted = !values.isRSVPRestricted;
                        }}
                      />
                    </span>
                  </span>
                </div>
              ) : null}
              <h1 className="title_toggle_panel">
                <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between' }}>
                  <span className="title_mediumLight box_size ">Add Note for Guests</span>
                  <span className="check-box small">
                    <Field
                      type="checkbox"
                      name="hasNotes"
                      onClick={() => {
                        values.hasNotes = !values.hasNotes;
                        values.notes = '';
                      }}
                    />
                  </span>
                </div>
                <span className="info_tooltip_panel">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tooltip-id="has_notes"></i>
                  <ReactTooltip id="has_notes">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Add any custom notes for your guests</span>
                    </div>
                  </ReactTooltip>
                </span>
              </h1>
              {values.hasNotes ? (
                <div className="additional_setting_toggle_child">
                  <span className="field_container">
                    <textarea
                      name="notes"
                      value={values.notes || ''}
                      style={{ height: '75px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}></textarea>
                  </span>
                </div>
              ) : null}
              <h1 className="title_toggle_panel">
                <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between' }}>
                  <span className="title_mediumLight box_size ">Add Meal Choices</span>
                  <span
                    className="check-box small"
                    onClick={() => {
                      values.hasMeal = !values.hasMeal;
                      values.meals = [
                        {
                          name: '',
                          isveg: 'true'
                        }
                      ];
                    }}>
                    <Field type="checkbox" name="hasMeal" />
                  </span>
                </div>
                <span className="info_tooltip_panel">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tooltip-id="meal_choice"></i>
                  <ReactTooltip id="meal_choice">
                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Add any custom notes for your guests</span>
                    </div> */}
                  </ReactTooltip>
                </span>
              </h1>
              {values.hasMeal ? (
                <div className="additional_setting_toggle_child">
                  <FieldArray name="meals">
                    {({ push, remove }) => (
                      <div>
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {values.meals &&
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          values.meals.map((meal: any, index: any) => {
                            const name = `meals[${index}].name`;
                            return (
                              <div
                                className="multi_field_container flex-wrap justify-content-end"
                                key={index}>
                                <div className="d-flex gap-3 w-100">
                                  <span className="field_container w-50">
                                    <label className="field_title">Meal Option</label>
                                    <input
                                      type="text"
                                      name={name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={meal.name || ''}
                                    />
                                  </span>
                                </div>
                                <button
                                  className="btn without-border justify-content-end p-0"
                                  style={{
                                    minWidth: 'unset',
                                    top: '-10px',
                                    position: 'relative',
                                    gap: '8px',
                                    color: '#f05537',
                                    fontSize: '13px',
                                    fontWeight: '400'
                                  }}
                                  type="button"
                                  onClick={() => remove(index)}>
                                  <i className="fal fa-times"></i> Remove
                                </button>
                              </div>
                            );
                          })}
                        <button
                          className="btn without-border p-0 gap-1 my-3"
                          type="button"
                          onClick={() => push({ name: '', isveg: 'true' })}>
                          <i className="fal fa-plus" /> Add Meal Options
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
              ) : null}
              <h1 className="title_toggle_panel">
                <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between' }}>
                  <span className="title_mediumLight box_size ">Allow guest to add new guest</span>
                  <span className="check-box small">
                    <Field
                      type="checkbox"
                      name="guestsAllowedToAddGuest"
                      onClick={() => {
                        values.guestsAllowedToAddGuest = !values.guestsAllowedToAddGuest;
                        values.noOfGuestsAllowed = 1;
                      }}
                    />
                  </span>
                </div>
                <span className="info_tooltip_panel">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tooltip-id="noOfGuestAllowed"></i>
                  <ReactTooltip id="noOfGuestAllowed">
                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Add any custom notes for your guests</span>
                    </div> */}
                  </ReactTooltip>
                </span>
              </h1>
              {values.guestsAllowedToAddGuest ? (
                <div className="additional_setting_toggle_child">
                  <span className="field_container w-50">
                    <label className="field_title">Number of Guests</label>
                    <DropDown
                      name="noOfGuestsAllowed"
                      onChange={(option: { value: number; label: string }) => {
                        setFieldValue('noOfGuestsAllowed', option.value);
                      }}
                      options={mapDataToDropDown(
                        [...Array(10).keys()].map((index) => ({ id: index + 1, name: index + 1 }))
                      )}
                      selectedOptions={{
                        id: values.noOfGuestsAllowed,
                        name: values.noOfGuestsAllowed
                      }}
                    />
                  </span>
                </div>
              ) : null}
              <h1 className="title_toggle_panel border-0">
                <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between' }}>
                  <span className="title_mediumLight box_size ">Add Schedule</span>
                  <span className="check-box small">
                    <Field
                      type="checkbox"
                      name="hasSchedule"
                      onClick={() => {
                        setHasSchedule(!hasSchedule);
                        values.hasSchedule = !values.hasSchedule;
                        values.eventSchedules = [
                          { name: '', startTime: '', endTime: '', note: '' }
                        ];
                      }}
                    />
                    {/* <input type="checkbox" onChange={() => setMoreSchedule(!showSchedule)} /> */}
                  </span>
                </div>
                <span className="info_tooltip_panel">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tooltip-id="showSchedule"></i>
                  <ReactTooltip id="showSchedule">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Provide specific details about significant moments</span>
                      <span>that guests won't want to miss.</span>
                      <span>
                        Help attendees plan their experience by outlining pivotal event highlights.
                      </span>
                    </div>
                  </ReactTooltip>
                </span>
              </h1>
              {values.hasSchedule ? (
                <div className="additional_setting_toggle_child">
                  <FieldArray name="eventSchedules">
                    {({ push, remove }) => (
                      <div>
                        {values.eventSchedules &&
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          values.eventSchedules.map((events: any, index: number) => {
                            const name = `eventSchedules[${index}].name`;
                            const startTime = `eventSchedules[${index}].startTime`;
                            const endTime = `eventSchedules[${index}].endTime`;
                            const note = `eventSchedules[${index}].note`;

                            return (
                              <div className="multi_field_container flex-column" key={index}>
                                <div className="d-flex w-100 gap-3 divider">
                                  <span className="field_container w-50">
                                    <label className="field_title">Name</label>
                                    <input
                                      type="text"
                                      name={name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={events?.name || ''}
                                    />
                                  </span>
                                  <span className="field_container w-25">
                                    <label className="field_title">Start Time</label>
                                    <input
                                      type="time"
                                      name={startTime}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={events?.startTime}
                                    />
                                  </span>
                                  <span className="field_container w-25">
                                    <label className="field_title">End Time</label>
                                    <input
                                      type="time"
                                      name={endTime}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={events?.endTime}
                                    />
                                  </span>
                                </div>
                                <span className="field_container w-100">
                                  <label className="field_title">Note</label>
                                  <textarea
                                    name={note}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ height: '75px' }}
                                    value={events?.note || ''}></textarea>
                                </span>

                                <button
                                  className="btn without-border justify-content-end p-0"
                                  style={{
                                    minWidth: 'unset',
                                    top: '-10px',
                                    position: 'relative',
                                    color: '#f05537',
                                    fontSize: '13px',
                                    fontWeight: '400'
                                  }}
                                  type="button"
                                  onClick={() => remove(index)}>
                                  <i className="fal fa-times"></i> Remove
                                </button>
                              </div>
                            );
                          })}
                        <button
                          className="btn without-border p-0 gap-1 my-3"
                          type="button"
                          onClick={() =>
                            push({
                              name: '',
                              note: '',
                              startTime: '',
                              endTime: ''
                            })
                          }>
                          <i className="fal fa-plus" /> Add More Schedules
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
              ) : null}
            </div>
            <button type="submit" className="btn primary" style={{ maxWidth: 'fit-content' }}>
              Save
            </button>
          </div>
          {/* <footer>
            <button type="submit" className="btn primary">
              Save
            </button>
          </footer> */}
        </form>
      )}
    </Formik>
  );
}
