import httpService from '@viteplan/services/http.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const adminTemplateList = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get('admin-template').then((res: any) => res);
};

export const adminTemplateById = (id: number, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`admin-template/${id}`, config).then((res: any) => res);
};

export const adminTemplateThemes = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get('admin-template/themes').then((res: any) => res);
};
