import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContext = createContext<any>({} as any);
const initAuth = {};

const getInitialAuth = () => {
  const auth = localStorage.getItem('user');
  return auth ? JSON.parse(auth) : initAuth;
};

// Function to get the authorization token from localStorage
const getAuthTokenFromLocalStorage = () => {
  return localStorage.getItem('authToken');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AuthProvider(props: any) {
  const [auth, setAuth] = useState(getInitialAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (getAuthTokenFromLocalStorage() === null && auth.user) {
      setAuth({});
      localStorage.clear();
      navigate('/login');
    }
    localStorage.setItem('user', JSON.stringify(auth));
  }, [auth]);

  return <AuthContext.Provider value={{ auth, setAuth }}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;
