import httpService from '@viteplan/services/http.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const userTemplate = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`user-template`, body, config).then((res: any) => res);
};

export const userTemplateById = (userTemplateId: number) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`user-template/${userTemplateId}`).then((res: any) => res);
};
