import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './components/ReduxStore/store';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GlobalDrawerContextProvider } from './context-api/GlobalDrawerContextProvider';
import { AuthProvider } from './context-api/AuthProvider';

const Loader = lazy(() => import('./views/Loader/Loader'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
document.body.classList.add('primary-setup');

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense
        fallback={
          <div>
            <div className="loader_container">
              <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                <Loader />
              </div>
            </div>
          </div>
        }>
        <AuthProvider>
          <GlobalDrawerContextProvider>
            <App />
          </GlobalDrawerContextProvider>
        </AuthProvider>
      </Suspense>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
