import React from 'react';
import { NavLink } from 'react-router-dom';
import './_link-card.scss';

/**
 * @memberof Cards
 */

/**
 * The props type for {@link LinkCard}.
 *
 * The props type is defined as a separate interface
 *
 * ```tsx
 *  export interface ILinkCardProps {
 *    // ...
 *  }
 * ```
 * @interface
 *
 */
export interface ILinkCardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
}
/**
 * Renders a LinkCard  around some content
 *
 * ```tsx
 *  <LinkCard key={item.id} item={item} />
 * ```
 *
 * Function implementation
 *
 * ```tsx
 *  export function LinkCard({props}: ILinkCardProps): React.JSX.Element {
 *    //...
 *  }
 * ```
 *
 *
 * @category Component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LinkCard(props: ILinkCardProps): React.JSX.Element {
  const { item } = props;
  return (
    <NavLink to={item.link} style={{ textDecoration: 'none', color: 'rgb(57,54,85)' }}>
      <button className="btn primary small">
        {item.title}
        <i className="fal ms-2 fa-long-arrow-right" />
      </button>
      {/* <p>{item.content}</p> */}
    </NavLink>
  );
}
