import { sendCommunicationMessage } from '@viteplan/services/communication.service';
import { prepareCommunicationMessage } from '@viteplan/services/invitation-message.service';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Guest } from 'views/Guests/models/guest.model';
import GuestSelection from 'views/Guests/partials/GuestSelection';
import { getAllGuests } from 'views/Guests/slice/guest.slice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getWeddingWebsiteUrl } from '@viteplan/util/urls';
import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import { CommunicationDetails } from 'views/Guests/models/communication-template.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ShareWebsite(props: any) {
  const { data } = props;
  const { auth } = useAuth();
  const dispatch = useAppDispatch();
  const { guests } = useAppSelector((state) => state.guest);
  const { hideDrawer } = useGlobalDrawerContext();

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const eventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  const [showGuestSelection, setShowGuestSelection] = useState<boolean>(false);
  const [hideGuestBackButtonIcon, setHideGuestBackButtonIcon] = useState<boolean>(true);

  const userProfileName =
    eventGroup?.eventGroupDetails?.firstName +
    ' & ' +
    eventGroup?.eventGroupDetails?.partnersFirstName;

  const [websiteDetails, setWebsiteDetails] = useState<CommunicationDetails>({
    subject: 'Wedding Website',
    from: userProfileName,
    message: `We're thrilled to share in the joy of our wedding with you! For comprehensive information, including our journey together, cherished moments captured in photos, registry details, and helpful travel tips, we invite you to explore our dedicated website.`,
    buttonText: 'View Website',
    link: ''
  });

  useEffect(() => {
    if (eventGroup) {
      dispatch(getAllGuests(eventGroup.id));
    }
  }, []);

  useEffect(() => {
    const uniqueKey = eventGroup?.uniqueKey;
    const websiteUrl = getWeddingWebsiteUrl(uniqueKey);
    setWebsiteDetails((prevState) => ({
      ...prevState,
      link: websiteUrl
    }));
  }, [eventGroup]);

  const hideGuestButton = (data: boolean) => {
    setHideGuestBackButtonIcon(data);
  };

  const getSelectedGuests = async (Guests: Guest[]) => {
    if (Guests.length > 0) {
      try {
        const message = prepareCommunicationMessage('SHARE_WEBSITE', {
          eventGroupDetails: eventGroup?.eventGroupDetails,
          guests: Guests,
          metaData: websiteDetails,
          communicationType: data.email ? 'Email' : data.sms ? 'SMS' : 'WhatsApp'
        });
        const response = await sendCommunicationMessage(eventGroup?.id, message);
        if (response.data.data) {
          toast.success('Message Sent Successfully.', {
            position: toast.POSITION.TOP_RIGHT
          });
          hideDrawer();
        }
      } catch (e) {
        console.log('Error Occured ---->', e);
      }
    }
  };

  if (!showGuestSelection) {
    return (
      <>
        {' '}
        <div className="compose_drawer">
          <div id="new_email" className="nw_email new-email d-flex flex-column gap-4">
            <h1 className="title_mediumLight"> {data.email ? 'New Email' : 'New SMS'} </h1>
            <div className="form_input">
              <div className="group_field_container">
                {data.email && (
                  <span className="field_container">
                    <label className="field_title">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={websiteDetails.subject}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setWebsiteDetails((prev: CommunicationDetails) => ({
                          ...prev,
                          subject: e.target.value
                        }))
                      }
                    />
                  </span>
                )}
                {data.email && (
                  <span className="field_container">
                    <label className="field_title">From</label>
                    <input
                      type="text"
                      placeholder="From"
                      name="from"
                      value={websiteDetails.from}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setWebsiteDetails((prev: CommunicationDetails) => ({
                          ...prev,
                          from: e.target.value
                        }))
                      }
                    />
                  </span>
                )}
                <span className="field_container">
                  <label className="field_title">Message</label>
                  <textarea
                    name="message"
                    rows={10}
                    style={{ height: 'unset', padding: '5px' }}
                    value={websiteDetails.message}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setWebsiteDetails((prev: CommunicationDetails) => ({
                        ...prev,
                        message: e.target.value
                      }))
                    }></textarea>
                </span>
                {data.email && (
                  <span className="field_container">
                    <label className="field_title">Change Button Text</label>
                    <input
                      type="text"
                      placeholder="Fill Out Form"
                      name="buttonText"
                      value={websiteDetails.buttonText}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setWebsiteDetails((prev: CommunicationDetails) => ({
                          ...prev,
                          buttonText: e.target.value
                        }))
                      }
                    />
                  </span>
                )}
              </div>
            </div>
            <footer>
              <span className="compose_close_action d-flex gap-3">
                <button className="btn" type="submit">
                  Cancel
                </button>
                <button className="btn primary" onClick={() => setShowGuestSelection(true)}>
                  Select Guests
                </button>
              </span>{' '}
            </footer>
          </div>
        </div>
      </>
    );
  }

  if (showGuestSelection) {
    return (
      <>
        {hideGuestBackButtonIcon && (
          <button
            className="fit-to-content without_background with-icon"
            style={{
              border: 'none',
              background: 'none',
              width: 'auto',
              textAlign: 'left',
              paddingBottom: '10px'
            }}
            onClick={() => {
              setShowGuestSelection(false);
            }}>
            <i className="far fa-long-arrow-left"></i> Back
          </button>
        )}
        <GuestSelection
          guestInfo={guests}
          onGuestSave={getSelectedGuests}
          hideBacKButton={hideGuestButton}
        />
      </>
    );
  }
}

export default ShareWebsite;
