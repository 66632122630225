import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EventSelectionItem(props: any) {
  const { data, onChange, selectedItem, isChecked } = props;
  return (
    <div className="list_box d-flex gap-3">
      <div>
        <div className="Checkbox">
          <input
            type="checkbox"
            id={selectedItem.id}
            value={selectedItem}
            onChange={onChange}
            checked={isChecked}
          />
          <div className="Checkbox-visible"></div>
        </div>
      </div>
      <div className="list_text">
        <span className="event_text"> {data.name}</span>
        <span className="event_text"> on {data.date}</span>
        <span className="event_text">at {data.venue}</span>
      </div>
    </div>
  );
}

export default EventSelectionItem;
