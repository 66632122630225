import React from 'react';
import { useNavigate } from 'react-router';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <section
        className="page_not_found p-4 text-center d-flex align-items-center "
        style={{ backgroundColor: '#fbf5eb' }}>
        <div className="container">
          <div className="not_fount_wrapper d-flex flex-column gap-3 ">
            <h1 className="mb-2" style={{ fontSize: '90px', color: '#bc8129', lineHeight: '48px' }}>
              404
            </h1>
            <h2 className="">Page Not Found</h2>
            <p>We Couldn't find the page you were looking for.</p>
            <div className="">
              <button type="button" className="btn primary" onClick={() => navigate('/')}>
                Back to Home
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PageNotFound;
