/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, useContext } from 'react';
import GuestSelection from 'views/Guests/partials/GuestSelection';
import RsvpResponses from 'views/Guests/partials/RsvpResponses';
import AddInvitation from 'views/Invitation/partials/AddInvitation';
import AddSaveTheDate from 'views/SaveTheDate/partials/AddSaveTheDate';
import SaveTheDateMeta from 'views/SaveTheDate/partials/SaveTheDateMeta';
import AddEditGreetings from 'views/SlamBook/partials/AddEditGreetings';
import ViewMemories from 'views/SlamBook/partials/ViewMemories';
import VendorImageUpload from 'views/Vendor/partials/VendorImageUpload';
import EventsDrawer from 'views/VideoInvitation/partials/EventsDrawer';
import AddEditContent from 'views/WeddingWebsite/manage-website/partials/AddEditContent';
import ShareWebsite from 'views/WeddingWebsite/manage-website/partials/ShareWebsite';
import WebsiteTheme from 'views/WeddingWebsite/manage-website/partials/WebsiteTheme';
import InvitationMeta from '../views/Editor/partials/InvitationMeta';
import { AddEditEvent } from '../views/Events/partials/AddEditEvent';

const SwitchAccount = lazy(() => import('views/Profile/partials/SwitchAccount'));
const AddGuest = lazy(() => import('../views/Guests/partials/AddGuest'));
const EditIndividualGuest = lazy(() => import('../views/Guests/partials/EditIndividualGuest'));
const HostRsvp = lazy(() => import('../views/Guests/partials/HostRsvp'));

const RsvpGrid = lazy(() => import('views/Rsvp/RsvpGrid'));
const CommunicationTemplates = lazy(() => import('views/Guests/partials/CommunicationTemplates'));
const Messages = lazy(() => import('views/Messages/Messages'));
const RsvpCount = lazy(() => import('views/Rsvp/partials/RsvpCount'));

export const DRAWER_INNER_COMPONENTS_TYPE = {
  /* LOGIN_DRAWER: 'LOGIN_DRAWER',
  SIGNUP_DRAWER: 'SIGNUP_DRAWER', */
  ADD_EDIT_EVENT_DRAWER: 'ADD_EDIT_EVENT_DRAWER',
  ADD_EDIT_GUEST_DRAWER: 'ADD_EDIT_GUEST_DRAWER',
  EVENT_SELECTION_DRAWER: 'EVENT_SELECTION_DRAWER',
  EDIT_GUEST_DRAWER: 'EDIT_GUEST_DRAWER',
  ADD_INVITATION: 'ADD_INVITATION',
  MESSAGE_DRAWER: 'MESSAGE_DRAWER',
  WEBSITE_EDITOR_DRAWER: 'WEBSITE_EDITOR_DRAWER',
  VIEW_MEMORIES: 'VIEW_MEMORIES',
  ADD_EDIT_GREETING: 'ADD_EDIT_GREETING',
  WEBSITE_THEME: 'WEBSITE_THEME',
  SAVE_THE_DATE_META: 'SAVE_THE_DATE_META',
  Add_SAVE_THE_DATE: 'ADD_SAVE_THE_DATE',
  SWITCH_ACCOUNT: 'SWITCH_ACCOUNT',
  COMMUNICATION_TEMPLATES: 'COMMUNICATION_TEMPLATES',
  RSVP_RESPONSES: 'RSVP_RESPONSES',
  RSVP_COUNT: 'RSVP_COUNT',
  GUEST_SELECTION_DRAWER: 'GUEST_SELECTION_DRAWER',
  SHARE_WEBSITE: 'SHARE_WEBSITE',
  VENDOR_IMAGE_UPLOAD: 'VENDOR_IMAGE_UPLOAD',
  RSVP_GRID: 'RSVP_GRID',
  EVENTS_DRAWER: 'EVENTS_DRAWER',
  HOST_RSVP: 'HOST_RSVP'
};

export const DRAWER_INNER_COMPONENTS: any = {
  /* [DRAWER_INNER_COMPONENTS_TYPE.LOGIN_DRAWER]: Login,
  [DRAWER_INNER_COMPONENTS_TYPE.SIGNUP_DRAWER]: SignUp, */
  [DRAWER_INNER_COMPONENTS_TYPE.ADD_EDIT_EVENT_DRAWER]: AddEditEvent,
  [DRAWER_INNER_COMPONENTS_TYPE.ADD_EDIT_GUEST_DRAWER]: AddGuest,
  [DRAWER_INNER_COMPONENTS_TYPE.EDIT_GUEST_DRAWER]: EditIndividualGuest,
  [DRAWER_INNER_COMPONENTS_TYPE.EVENT_SELECTION_DRAWER]: InvitationMeta,
  [DRAWER_INNER_COMPONENTS_TYPE.ADD_INVITATION]: AddInvitation,
  [DRAWER_INNER_COMPONENTS_TYPE.MESSAGE_DRAWER]: Messages,
  [DRAWER_INNER_COMPONENTS_TYPE.WEBSITE_EDITOR_DRAWER]: AddEditContent,
  [DRAWER_INNER_COMPONENTS_TYPE.VIEW_MEMORIES]: ViewMemories,
  [DRAWER_INNER_COMPONENTS_TYPE.ADD_EDIT_GREETING]: AddEditGreetings,
  [DRAWER_INNER_COMPONENTS_TYPE.WEBSITE_THEME]: WebsiteTheme,
  [DRAWER_INNER_COMPONENTS_TYPE.SAVE_THE_DATE_META]: SaveTheDateMeta,
  [DRAWER_INNER_COMPONENTS_TYPE.Add_SAVE_THE_DATE]: AddSaveTheDate,
  [DRAWER_INNER_COMPONENTS_TYPE.SWITCH_ACCOUNT]: SwitchAccount,
  [DRAWER_INNER_COMPONENTS_TYPE.COMMUNICATION_TEMPLATES]: CommunicationTemplates,
  [DRAWER_INNER_COMPONENTS_TYPE.RSVP_RESPONSES]: RsvpResponses,
  [DRAWER_INNER_COMPONENTS_TYPE.RSVP_COUNT]: RsvpCount,
  [DRAWER_INNER_COMPONENTS_TYPE.GUEST_SELECTION_DRAWER]: GuestSelection,
  [DRAWER_INNER_COMPONENTS_TYPE.SHARE_WEBSITE]: ShareWebsite,
  [DRAWER_INNER_COMPONENTS_TYPE.VENDOR_IMAGE_UPLOAD]: VendorImageUpload,
  [DRAWER_INNER_COMPONENTS_TYPE.RSVP_GRID]: RsvpGrid,
  [DRAWER_INNER_COMPONENTS_TYPE.EVENTS_DRAWER]: EventsDrawer,
  [DRAWER_INNER_COMPONENTS_TYPE.HOST_RSVP]: HostRsvp
};

type DrawerContextType = {
  showDrawer: (drawerType: string, drawerProps?: any) => void;
  hideDrawer: () => void;
  store: any;
};

const initalState: DrawerContextType = {
  showDrawer: () => {},
  hideDrawer: () => {},
  store: {}
};

export const GlobalDrawerContext = React.createContext<DrawerContextType>(initalState);
export const useGlobalDrawerContext = () => useContext(GlobalDrawerContext);
