import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { formatLocalizedDate } from '@viteplan/util/date.util';
import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThreeDotsLoader from 'views/Loader/ThreeDotsLoader';
import { imageupload } from 'views/WeddingWebsite/service/website-setting.service';
import { backGroundTextureImages } from '../data/background-texture.data';
import { getGreetingcardLayout, greetingCardLayout } from '../services/slambook.service';

function AddEditGreetings() {
  const { auth } = useAuth();
  const { hideDrawer } = useGlobalDrawerContext();

  const [message, setMessage] = useState('The Wedding Day');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [greetingMasterData, setGreetingMasterData] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedImage, setSelectedImage] = useState<any>(
    'https://viteplan.s3.amazonaws.com/request_info_template/4_230706055849_wedd.jpg'
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [backGroundImage, setBackGroundImage] = useState<any>(
    'https://viteplan.s3.amazonaws.com/app-assets/cover_1.jpg'
  );
  const [isLoading, setIsLoading] = useState(false);

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const eventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  // Function to handle image click and set the selected image
  const handleImageClick = (imagePath: string) => {
    setBackGroundImage(imagePath);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleImageChange = async (event: any) => {
    setIsLoading(true);
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('files', file);
      const response = await imageupload(eventGroup?.id, formData);
      if (response.data.data) {
        setSelectedImage(response.data.data[0]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error handling image change:', error);
      setIsLoading(false);
    }
  };

  const getGreetingMaster = async (eventGroupId: number | undefined) => {
    const response = await getGreetingcardLayout(eventGroupId);
    if (response.data.data) {
      setGreetingMasterData(response.data.data[0]);
      const metaData = JSON.parse(response.data.data[0].metaData);
      setMessage(metaData.message);
      setBackGroundImage(metaData.backgroundCover);
      setSelectedImage(metaData.profileImage);
    }
  };

  const saveGreeting = async () => {
    try {
      const metaData = {
        profileImage: selectedImage,
        message: message,
        backgroundCover: backGroundImage
      };

      const request = {
        id: greetingMasterData ? greetingMasterData?.id : null,
        eventGroupId: eventGroup?.id,
        metaData: JSON.stringify(metaData)
      };
      const response = await greetingCardLayout(request);
      if (response.data.data) {
        toast.success('Greeting card updated. The changes have been applied successfully.', {
          position: toast.POSITION.TOP_RIGHT
        });
        hideDrawer();
      }
    } catch (e) {
      console.log('Error Occured');
    }
  };

  useEffect(() => {
    getGreetingMaster(eventGroup?.id);
  }, []);

  return (
    <>
      <div className="">
        <div className="content_list">
          <div className="box-content">
            <h2>Edit Greeting Card</h2>
            <p>Add your photo, message and cover images</p>

            <div className="both_content">
              <section className="slam_book p-0 right_content">
                <div className="flex-grow-1 flex-shrink-1 d-flex">
                  <div className="book-contain">
                    <div className="book">
                      <div className="book_content"></div>
                      <div className={`cover-book`}>
                        <div
                          className="cover"
                          style={{
                            backgroundImage: `url(${backGroundImage}`
                          }}>
                          <span className="close-icon">
                            <i className="fas fa-sign-out-alt"></i>
                          </span>
                          <div className="cover-content">
                            <span>
                              <h4>
                                <code>
                                  {message} <br />
                                </code>
                              </h4>
                              <h5>
                                {eventGroup?.eventGroupDetails?.dateOfWedding
                                  ? formatLocalizedDate(
                                      eventGroup?.eventGroupDetails?.dateOfWedding
                                    )
                                  : ''}
                              </h5>
                            </span>
                            <div className="wedding_image">
                              <span>
                                {isLoading ? (
                                  <ThreeDotsLoader />
                                ) : (
                                  <img src={selectedImage} alt="" />
                                )}
                              </span>
                            </div>
                            <div className="couple_name">
                              {eventGroup?.eventGroupDetails?.firstName}{' '}
                              <span className="ms-0 ms-sm-5">
                                & {eventGroup?.eventGroupDetails?.partnersFirstName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="left_content">
                <div className="group_field_container ">
                  <span className="field_container">
                    <label className="field_title">Heading</label>
                    <input
                      placeholder="Your Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </span>
                  <div className="upload_img">
                    <div className="border_dotted">
                      <span className="icon-img">
                        {selectedImage ? (
                          <img src={selectedImage} alt="no" height={50} width={50} srcSet="" />
                        ) : (
                          <i className="far fa-images" />
                        )}
                      </span>

                      <span className="file_upload">
                        <label className="custom-file-upload">
                          <input
                            className="upload_field"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                          Choose file
                        </label>
                      </span>
                    </div>
                  </div>
                  <span className="field_container">
                    <label className="field_title">Background Texture</label>
                    <div className="texture-images">
                      {backGroundTextureImages.map((imagePath: string, index: number) => (
                        <img
                          key={index}
                          src={imagePath}
                          alt=""
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleImageClick(imagePath);
                          }}
                        />
                      ))}
                    </div>
                  </span>

                  <button
                    className="btn primary"
                    style={{ maxWidth: 'fit-content' }}
                    onClick={saveGreeting}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
}

export default AddEditGreetings;
