import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addEvent, eventLists, deleteEvent } from '../services/event.service';

export const getAllEvents = createAsyncThunk(
  'getEvents',
  async (eventGroupId: number | undefined) => {
    const response = await eventLists(eventGroupId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addEventData = createAsyncThunk(
  'addEvent',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async ({ eventGroupId, data }: { eventGroupId: number | undefined; data: any }) => {
    const response = await addEvent(eventGroupId, data);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateEventData = createAsyncThunk(
  'updateEvent',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async ({ eventGroupId, data }: { eventGroupId: number | undefined; data: any }) => {
    const response = await addEvent(eventGroupId, data);
    return response.data;
  }
);
//Delete Event
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteEventData = createAsyncThunk('deleteEvent', async (data: any) => {
  await deleteEvent({ data: data });
  return data;
});

interface EventState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventGroup: any;
  loading: boolean;
  error: null | string;
}

const initialState: EventState = {
  eventGroup: [],
  loading: false,
  error: null
};

export const eventSlice = createSlice({
  name: 'eventGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getAllEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.eventGroup = data;
    });
    builder.addCase(getAllEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    //ADD EVENT
    builder.addCase(addEventData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addEventData.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.eventGroup.events.push(data);
    });
    builder.addCase(addEventData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    // UPDATE Event
    builder.addCase(updateEventData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateEventData.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.eventGroup.events.map((item: any, index: number) => {
        if (item.id === data.id) state.eventGroup.events[index] = data;
        return null;
      });

      for (let item of state.eventGroup.events) {
        if (item.id === data.id) {
          item = data;
        }
      }
      /* state.eventGroup.events.each((item: any) => {
        if (item.id === data.id) {
          item = data;
        }
      }); */
    });
    builder.addCase(updateEventData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    //DELETE EVENT
    builder.addCase(deleteEventData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEventData.fulfilled, (state, action) => {
      state.loading = false;
      state.eventGroup.events = state.eventGroup.events.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => !action.payload.includes(item.id)
      );
    });
    builder.addCase(deleteEventData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});
export default eventSlice.reducer;
