import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { videoById, videoByUrl, videolist } from '../service/video-invitation.service';

export const getAllVideoList = createAsyncThunk(
  'videolist',
  async ({ pageNo, pageSize }: { pageNo: number; pageSize: number }) => {
    const response = await videolist(pageNo, pageSize);
    return response.data;
  }
);

export const getVideoById = createAsyncThunk('videoById', async (videoId: number) => {
  const response = await videoById(videoId);
  return response.data;
});

export const getVideoByUrl = createAsyncThunk('videoByUrl', async (videoUrl: string) => {
  const response = await videoByUrl(videoUrl);
  return response.data;
});

interface VideoState {
  videoList: [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoById: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoByUrl: Record<string, any>;
  loading: boolean;
  error: null | string;
}

const initialState: VideoState = {
  videoList: [],
  videoById: {},
  videoByUrl: {},
  loading: false,
  error: null
};

export const videoSlice = createSlice({
  name: 'videoSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL Video List
    builder.addCase(getAllVideoList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVideoList.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.videoList = data;
    });
    builder.addCase(getAllVideoList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Video Data By ID
    builder.addCase(getVideoById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVideoById.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.videoById = data;
    });
    builder.addCase(getVideoById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Video Data By Url
    builder.addCase(getVideoByUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVideoByUrl.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.videoByUrl = data;
    });
    builder.addCase(getVideoByUrl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default videoSlice.reducer;
