import httpService from '@viteplan/services/http.service';

export const invitationList = (eventGroupId: number | undefined, config = {}) => {
  return (
    httpService
      .get(`invitation?eventGroupId=${eventGroupId}`, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addInvitation = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`invitation`, body, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteInvitation = (invitationId: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.delete(`invitation/${invitationId}`).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkInvitation = (invitationCode: any, guestGUID: string) => {
  return (
    httpService
      .get(`invitation/check?invitationCode=${invitationCode}&guestGUID=${guestGUID}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGuestsInvitation = (invitationCode: any, guestGUID: string) => {
  return (
    httpService
      .get(`invitation/detail-by-code?invitationCode=${invitationCode}&guestGUID=${guestGUID}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rsvpAnswers = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`rsvp/answer`, body, config).then((res: any) => res);
};

export const invitationDetailById = (invitationId: number) => {
  return (
    httpService
      .get(`invitation/${invitationId}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteRsvp = (config: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.delete(`rsvp/answer/delete`, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateGuestInInvitation = (guestGroupId: number, invitaionId: number, body: any) => {
  return (
    httpService
      .post(`guest/group/${guestGroupId}/update-in-invitation/${invitaionId}`, body)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};
