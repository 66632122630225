/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropDown } from '@viteplan/components/common/DropDown';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { mapDataToDropDown } from '@viteplan/util/dropdown.util';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GuestSelectionItem from 'views/Editor/partials/GuestSelectionItem';
import { getAllEvents } from 'views/Events/slice/event-slice';
import { Guest } from '../models/guest.model';
import { filterGuests, flattenedGuests } from '../services/guest-helper.service';
import { uploadGuestFile } from '../services/guest.service';
import { getAllGuests } from '../slice/guest.slice';
import AddIndividualGuest from './AddIndividualGuest';

function GuestSelection({
  guestInfo,
  onGuestSave,
  hideBacKButton = () => {},
  buttonText = 'Save'
}: any) {
  const { auth } = useAuth();
  const dispatch = useAppDispatch();
  const { eventGroup } = useAppSelector((state) => state.event);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [guestArray, setGuestState] = useState<any>([]);
  const [allGuests, setGuestData] = useState<Guest[]>([]);
  const [showAddGuestForm, setShowAddGuestForm] = useState(false);
  const [filter, setFilter] = useState<any>({
    searchTerm: '',
    rsvpAnswers: 'All',
    eventName: 'All',
    eventId: 0,
    invitation: 'All'
  });
  // const [filteredData, setFilterData] = useState<any>();

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);
  const allowedFileExtensions = ['xls', 'xlsx'];

  const [guestsCheckboxState, setGuestsCheckboxState] = useState(() =>
    allGuests.map((guest: Guest) =>
      guestArray?.some((selectedGuest: Guest) => selectedGuest.id === guest.id)
    )
  );

  const initialGuestsCheckboxState = allGuests?.map((item: Guest) => {
    const isMatch = guestArray?.some((guest: Guest) => guest.id === item.id) ? true : false;
    return isMatch;
  });

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    setGuestsCheckboxState(() => allGuests.map(() => checked));
    setGuestState(() => {
      if (checked) {
        return [...allGuests];
      } else {
        return [];
      }
    });
  };

  const handleGuestChange = (event: React.ChangeEvent<HTMLInputElement>, selectedGuest: Guest) => {
    const checked = event.target.checked;
    setSelectAllChecked(false);
    // Update the checkbox state based on the updated guestGroup
    setGuestsCheckboxState((prev) =>
      allGuests.map((guest: Guest) =>
        selectedGuest.id === guest.id
          ? checked
          : prev[allGuests.findIndex((g) => g.id === guest.id)]
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setGuestState((prevGuestGroup: any) => {
      if (checked) {
        return Array.from(new Set([...prevGuestGroup, selectedGuest]));
      } else {
        return prevGuestGroup.filter((guest: Guest) => guest.id !== selectedGuest.id);
      }
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((prev: any) => ({ ...prev, searchTerm: event.target.value }));
  };

  const handleFileSubmission = async (eventGroupID: number | undefined, formData: FormData) => {
    try {
      const response = await uploadGuestFile(eventGroupID, formData);
      if (response.data.success) {
        dispatch(getAllGuests(eventGroupID));
        toast.success('File uploaded successfully', { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.warning('File has no data', { position: toast.POSITION.TOP_RIGHT });
        console.log('Error', response);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Internal Server Error', { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const uploadGuestExcel = (event: any) => {
    try {
      const selectedFile = event.target.files[0];
      const fileExtension = (selectedFile.name.split('.').pop() || '').toLowerCase();

      // Check if the file extension is in the allowed extensions
      if (allowedFileExtensions.includes(fileExtension)) {
        const newFormData = new FormData();
        newFormData.append('file', selectedFile);
        handleFileSubmission(userEventGroup?.id, newFormData);
      } else {
        toast.error('Invalid file format. Please upload an Excel file', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  useEffect(() => {
    if (userEventGroup) dispatch(getAllEvents(userEventGroup?.id));
  }, []);

  useEffect(() => {
    const data = filterGuests(guestInfo, filter);
    setGuestData(data);
    // setFilterData(data);
  }, [filter]);

  //Flattened Guest Array
  useEffect(() => {
    const data = flattenedGuests(guestInfo);
    setGuestData(data);
  }, [guestInfo]);

  useEffect(() => {
    setGuestsCheckboxState(initialGuestsCheckboxState);
  }, [allGuests]);

  const setDrawerOpenState = (data: boolean) => {
    setShowAddGuestForm(!data);
    hideBacKButton(data);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      {!showAddGuestForm && (
        <>
          <div
            className="guest_selection_row d-flex flex-column h-100 gap-3"
            style={{ overflow: 'auto', paddingRight: '24px' }}>
            <div className="d-flex gap-3 flex-sm-row flex-column justify-content-end">
              <button
                className="btn activ pb-2 fit-to-content without_background with-icon"
                style={{ marginBottom: '16px', display: 'none', paddingBottom: '20px' }}>
                <i className="far fa-times"></i>
              </button>
              <div className="upload-guest d-flex gap-2">
                <label className="btn primary custom-file-upload">
                  <input
                    className="upload_field"
                    id="fileInput"
                    type="file"
                    name="Upload"
                    onChange={(e) => {
                      uploadGuestExcel(e);
                    }}
                  />
                  Upload guests
                </label>
                <NavLink to={process.env.REACT_APP_BASE_URL + 'guest/samplefile'} target="_blank">
                  Sample file
                </NavLink>
              </div>
              <button
                className="btn primary d-inline-flex gap-2"
                style={{ whiteSpace: 'nowrap', minWidth: 'unset', height: 'max-content' }}
                onClick={() => {
                  setShowAddGuestForm(true);
                  hideBacKButton(false);
                }}>
                <i className="fal fa-plus"></i> Add New Guest
              </button>
            </div>

            {/* Search Input */}
            <div className="d-flex gap-3 ">
              <div className="normal_search_panel my-2">
                <input
                  type="search"
                  className="search_field"
                  placeholder="Search..."
                  value={filter.searchTerm}
                  onChange={handleSearchChange}
                />
                <button className="btn without-border">
                  <i className="fal fa-search"></i>
                </button>
              </div>{' '}
            </div>
            <div className="group_field_container gap-4">
              <div className="multi_field_container d-flex gap-2 mb-3">
                <span className="field_container w-50 ">
                  <label className="field_title">Event Name</label>
                  <DropDown
                    id="eventName"
                    name="eventName"
                    offSet={13}
                    onChange={(option: { value: number; label: string }) => {
                      if (option.value === 0) {
                        setFilter((prev: any) => ({
                          ...prev,
                          rsvpAnswers: 'All'
                        }));
                      }
                      setFilter((prev: any) => ({
                        ...prev,
                        eventName: option.label,
                        eventId: option.value
                      }));
                      console.log('Event NAme', option);
                    }}
                    options={mapDataToDropDown(
                      eventGroup && eventGroup.events
                        ? [{ id: 0, name: 'All' }, ...eventGroup.events]
                        : []
                    )}
                    selectedOptions={{ name: 'All', id: 0 }}
                  />
                </span>
                <span className="field_container w-50 ">
                  <label className="field_title">Invitation</label>
                  <DropDown
                    id="invitation"
                    name="invitation"
                    offSet={13}
                    onChange={(option: { value: number; label: string }) => {
                      setFilter((prev: any) => ({ ...prev, invitation: option.label }));
                      console.log('Invitaion Change', option.label);
                    }}
                    options={mapDataToDropDown([
                      { id: 0, name: 'All' },
                      { id: 1, name: 'Invited' },
                      { id: 3, name: 'Not Invited' }
                    ])}
                    selectedOptions={{ id: 0, name: 'All' }}
                  />
                </span>
                <span className="field_container w-50 ">
                  <label className="field_title">Rsvp Answers</label>
                  <DropDown
                    id="rsvpAnswers"
                    name="rsvpAnswers"
                    offSet={13}
                    onChange={(option: { value: number; label: string }) => {
                      setFilter((prev: any) => ({ ...prev, rsvpAnswers: option.label }));
                    }}
                    options={mapDataToDropDown([
                      { id: 0, name: 'All' },
                      { id: 2, name: 'yes' },
                      { id: 3, name: 'no' },
                      { id: 4, name: 'no reply' }
                    ])}
                    selectedOptions={{ id: 0, name: 'All' }}
                    disabled={filter.invitation !== 'Invited' || filter.eventId === 0}
                  />
                </span>
              </div>
            </div>

            {/* Select All Guests */}

            <div className="Checkbox-all  d-flex gap-3">
              <div className="Checkbox">
                <input
                  type="checkbox"
                  checked={selectAllChecked}
                  onChange={(e) => handleSelectAllChange(e)}
                />
                <div className="Checkbox-visible"></div>
              </div>
              <span className="event_text" style={{ fontSize: '16px' }}>
                Select all guests
              </span>
            </div>
            <div className="event_list_container d-flex flex-column gap-3">
              {guestsCheckboxState &&
                Object.values(
                  allGuests.reduce((acc: any, val: any) => {
                    if (!acc[val.guestGroupId]) {
                      acc[val.guestGroupId] = [];
                    }
                    acc[val.guestGroupId].push(val);
                    return acc;
                  }, {})
                ).map((guestGroup: any) => (
                  <div
                    key={guestGroup[0].guestGroupId}
                    id={guestGroup[0].guestGroupId}
                    className={guestGroup.length > 1 ? 'group_guest_box' : ''}>
                    {/* Assuming guestGroupId is the same for all guests in a group */}
                    {guestGroup.map((val: Guest) => (
                      <GuestSelectionItem
                        key={val.id}
                        data={val}
                        selectedItem={val}
                        isChecked={
                          guestsCheckboxState[
                            allGuests.findIndex((guest) => guest.id === val.id)
                          ] || false
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleGuestChange(e, val);
                        }}
                      />
                    ))}
                  </div>
                ))}
            </div>

            {/* Save Button */}
          </div>
          <div className="bottom_save_button" style={{ position: 'relative', padding: '0' }}>
            <button
              className={guestArray.length !== 0 ? ' btn primary fit-to-content' : 'btn disabled'}
              style={{ maxWidth: 'fit-content' }}
              onClick={() => onGuestSave(guestArray)}>
              {buttonText}
            </button>
          </div>
        </>
      )}

      {/* Add Guest Form */}
      {showAddGuestForm && (
        <>
          <button
            className="fit-to-content without_background with-icon"
            style={{
              border: 'none',
              background: 'none',
              width: 'auto',
              textAlign: 'left',
              paddingBottom: '10px'
            }}
            onClick={() => {
              setShowAddGuestForm(false);
              hideBacKButton(true);
            }}>
            <i className="far fa-long-arrow-left"></i> Back
          </button>
          <AddIndividualGuest drawerOpenState={false} setDrawerOpenState={setDrawerOpenState} />
        </>
      )}
    </>
  );
}

export default GuestSelection;
