import httpService from '@viteplan/services/http.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const imageupload = (eventGroupId: number | undefined, body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    httpService
      .post(`event-group/${eventGroupId}/assets`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createWebsite = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    httpService
      .post(`website`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const getWebsiteById = (websiteId: number | undefined, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`website/${websiteId}`, config).then((res: any) => res);
};

export const weddingWebsite = (eventGroupId: number | undefined, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`website?eventGroupId=${eventGroupId}`, config).then((res: any) => res);
};

export const getWebsiteByUniqueKey = (eventGroupKey: string | undefined, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`website/eg/${eventGroupKey}`, config).then((res: any) => res);
};
