import React, { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ViewMemories(props: any) {
  const { data } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [otherMetaData, setOtherMetaData] = useState<any>();

  useEffect(() => {
    setOtherMetaData(JSON.parse(data.otherMetaData));
  }, [data]);

  return (
    <>
      {' '}
      <div className="book_content">
        <textarea
          readOnly
          className="textbox-lines bg-white"
          placeholder="I am congratulating you on your Wedding!"
          rows={3}
          value={data?.message}></textarea>{' '}
        <div className="upload">
          <div className="gallery selected-images my-3" style={{ maxHeight: 'max-content' }}>
            {otherMetaData?.upload.map((imageUrl: string, index: number) => (
              <div className="gallery__item">
                <img key={index} src={imageUrl} alt="" width="200" height="150" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMemories;
