import { useAppDispatch } from 'hooks/hooks';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import ThreeDotsLoader from 'views/Loader/ThreeDotsLoader';
import { VendorUpload } from '../models/Vendor.model';
import { vendorDeleteImageUploads, vendorUploads } from '../services/vendor.service';
import { getVendorById } from '../slice/vendor-slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function VendorImageUpload(props: any) {
  const { data } = props;
  const { auth } = useAuth();
  const dispatch = useAppDispatch();

  const [imageList, setImageList] = useState(data.vendorUploads);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (index: number, id: number) => {
    try {
      const response = await vendorDeleteImageUploads({ data: [id] });
      if (response.data.data) {
        const updatedImageList = [...imageList];
        updatedImageList.splice(index, 1);
        setImageList(updatedImageList);
        dispatch(getVendorById(auth.user.id));
      }
    } catch (e) {
      console.log('Errpr Occured', e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = async (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedFiles: any = Array.from(event.target.files);
    const formData = new FormData();
    setLoading(true);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }
    try {
      const response = await vendorUploads(data.id, formData);
      if (response.data.data) {
        setImageList((prevUrls: VendorUpload[]) => [...prevUrls, ...response.data.data]);
        dispatch(getVendorById(auth.user.id));
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setLoading(false);
      event.target.value = null;
    }
    event.target.value = null;
  };

  return (
    <>
      <div className="vendor_image_selection_drawer_footer" style={{ gap: '8px' }}>
        <input type="file" onChange={handleFileChange} multiple accept="image/*" />
        Upload More Photos
        <div className="upload_more_images" style={{ position: 'unset', cursor: 'pointer' }}>
          <i className="fal fa-plus"></i>
        </div>
      </div>
      <div className="vendor_image_selection_drawer">
        {imageList &&
          imageList.length !== 0 &&
          imageList?.map((item: VendorUpload, index: number) => {
            return (
              <div key={index}>
                <img src={item.assetPath} alt="viteplan" />
                <span className="img_action_panel">
                  <i className="fas fa-arrows-alt"></i>
                  <i className="far fa-trash-alt" onClick={() => handleDelete(index, item.id)}></i>
                </span>
              </div>
            );
          })}
        {loading && <ThreeDotsLoader />}
      </div>
    </>
  );
}

export default VendorImageUpload;
