import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ThreeDotsLoader({ props }: any) {
  return (
    <div id="img-1-loader" className={`img_loader ${props}`}>
      <svg
        id="img-1-loader_img"
        width="80"
        height="80"
        viewBox="0 0 120 30"
        xmlns="http://www.w3.org/2000/svg"
        fill="#bc8129"
        data-testid="three-dots-svg">
        <circle cx="15" cy="15" r="15">
          <animate
            attributeName="r"
            from="15"
            to="15"
            begin="0s"
            dur="0.8s"
            values="15;9;15"
            calcMode="linear"
            repeatCount="indefinite"></animate>
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"></animate>
        </circle>
        <circle cx="60" cy="15" r="9" attributeName="fill-opacity" from="1" to="0.3">
          <animate
            attributeName="r"
            from="9"
            to="9"
            begin="0s"
            dur="0.8s"
            values="9;15;9"
            calcMode="linear"
            repeatCount="indefinite"></animate>
          <animate
            attributeName="fill-opacity"
            from="0.5"
            to="0.5"
            begin="0s"
            dur="0.8s"
            values=".5;1;.5"
            calcMode="linear"
            repeatCount="indefinite"></animate>
        </circle>
        <circle cx="105" cy="15" r="15">
          <animate
            attributeName="r"
            from="15"
            to="15"
            begin="0s"
            dur="0.8s"
            values="15;9;15"
            calcMode="linear"
            repeatCount="indefinite"></animate>
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"></animate>
        </circle>
      </svg>
    </div>
  );
}

export default ThreeDotsLoader;
