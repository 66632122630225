import React, { useContext } from 'react';
import { Drawer, Space, Button } from 'antd';
import { GlobalDrawerContext } from '../../context-api/GlobalDrawerContext';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DrawerContainer(props: any) {
  const { hideDrawer } = useContext(GlobalDrawerContext);
  const { config } = props;
  const { drawerProps, open } = config;
  const hideCurrentDrawer = () => {
    hideDrawer();
  };
  return (
    <>
      <Drawer
        title={drawerProps.title}
        className={drawerProps.className}
        placement="right"
        open={open}
        onClose={hideCurrentDrawer}
        closable={false}
        maskClosable={false}
        headerStyle={{ height: '56px', borderBottom: '1px solid #ccc' }}
        width={drawerProps.width || 378}
        footer={drawerProps.isFooterVisible ? drawerProps.footerElement : ''}
        footerStyle={{
          height: '56px',
          borderTop: '1px solid #ccc',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        extra={
          <Space>
            <Button
              onClick={hideCurrentDrawer}
              className="btn without-border"
              style={{ minWidth: 'unset' }}>
              <i className="far fa-times"></i>
            </Button>
          </Space>
        }>
        <div style={{ textAlign: 'center', fontSize: '20px', color: 'red' }}>
          {drawerProps.message}
        </div>
        {drawerProps.editPanel
          ? React.cloneElement(props.children, {
              editPanel: true,
              data: drawerProps.data
            })
          : props.children}
      </Drawer>
    </>
  );
}
