import { sendCommunicationMessage } from '@viteplan/services/communication.service';
import { prepareCommunicationMessage } from '@viteplan/services/invitation-message.service';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllGuests } from 'views/Guests/slice/guest.slice';
import { addSaveTheDateUserTemplate } from '../slice/save-the-date.slice';
import GuestSelection from 'views/Guests/partials/GuestSelection';
import { Guest } from 'views/Guests/models/guest.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SaveTheDateMeta(data: any) {
  const { auth } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hideDrawer } = useGlobalDrawerContext();
  const { guests } = useAppSelector((state) => state.guest);

  const [selectedGuests, setSelectedGuests] = useState<Guest[]>();

  // const [guestArray, setGuestState] = useState<Guest[]>(
  //   data?.data?.saveTheDateObj
  //     ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       [...data?.data?.saveTheDateObj?.guests.map((item: any) => item?.guest)]
  //     : []
  // );

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  useEffect(() => {
    dispatch(getAllGuests(userEventGroup?.id));
  }, []);

  const getSelectedGuests = (data: Guest[]) => {
    setSelectedGuests(data);
  };

  const saveSaveTheDate = async () => {
    const request = {
      id: data.data.saveTheDateObj ? data.data.saveTheDateObj.id : null,
      name: '',
      description: '',
      saveTheDateTemplateId: data.data.saveTheDateObj
        ? data.data.saveTheDateObj.saveTheDateTemplateId
        : data.data.data.id,
      saveTheDateTemplate: data.data.saveTheDateObj
        ? data.data.saveTheDateObj.saveTheDateTemplate
        : data.data.data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      guests: selectedGuests?.map((item: any) => ({
        guestId: item.id
      })),
      eventGroupId: userEventGroup?.id,
      templateHtml: data.data.templateHtml
    };
    dispatch(addSaveTheDateUserTemplate(request));
    try {
      const message = await prepareCommunicationMessage('SAVE_THE_DATE', {
        eventGroupDetails: userEventGroup?.eventGroupDetails,
        guests: selectedGuests,
        saveTheDate: data.data
      });
      const response = await sendCommunicationMessage(userEventGroup?.id, message);
      if (response.data.data) {
        toast.success('Your Save the date card  has been sent successfully.', {
          position: toast.POSITION.TOP_RIGHT
        });
        hideDrawer();
        navigate('/manage-save-the-date');
      }
    } catch (e) {
      toast.error('Something went wrong.', {
        position: toast.POSITION.TOP_RIGHT
      });
      console.log('Error Occured ---->', e);
    }
  };

  useEffect(() => {
    if (selectedGuests && selectedGuests.length > 0) saveSaveTheDate();
  }, [selectedGuests]);

  return (
    <>
      {' '}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <GuestSelection
        guestInfo={guests}
        onGuestSave={getSelectedGuests}
        buttonText={'Save & Send'}
      />
    </>
  );
}

export default SaveTheDateMeta;
