import { Country } from '@viteplan/models/country.model';
import { State } from '@viteplan/models/state.model';
import httpService from '@viteplan/services/http.service';

/**
 * @summary Retrieves a list of countries.
 *
 * @memberof Services {@link Services}
 *
 * @param {object} [config={}] - Additional configuration for the request.
 * @returns {Promise<Country[]>} - The promise that resolves to an array of countries.
 *
 */
export const getCountries = (config = {}) => {
  return httpService.get(`master-data/countries`, config).then((res: Country[]) => res);
};
/**
 * @summary Retrieves a list of states for a specific country.
 *
 * @memberof Services {@link Services}
 *
 * @param {number} countryId - The ID of the country.
 * @param {object} [config={}] - Additional configuration for the request.
 * @returns {Promise<State[]>} - The promise that resolves to an array of states.
 *
 */
export const getStates = (countryId: number, config = {}) => {
  return httpService.get(`master-data/state/${countryId}`, config).then((res: State[]) => res);
};
