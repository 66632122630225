import { createSlice } from '@reduxjs/toolkit';

interface InvitationDrawerTitle {
  title: string;
}

const initialState = {
  title: 'Event Selection'
} as InvitationDrawerTitle;

const InvitationDrawerTitleSlice = createSlice({
  name: 'invitateDrawerTextSlice',
  initialState,
  reducers: {
    eventSelection(state) {
      state.title = 'Event Selection';
    },
    guestSelection(state) {
      state.title = 'Guest Selection';
    }
  }
});

export const { eventSelection, guestSelection } = InvitationDrawerTitleSlice.actions;
export default InvitationDrawerTitleSlice.reducer;
