/* eslint-disable @typescript-eslint/no-explicit-any */
import { Guest, GuestGroup } from '../models/guest.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapToGuestData = (data: GuestGroup) => {
  return {
    guests: guestData(data.guests),
    guestAddresses: [
      {
        id: data.guestAddresses[0]?.id,
        address: data.guestAddresses[0]?.address,
        city: data.guestAddresses[0]?.city,
        state: data.guestAddresses[0]?.state,
        country: data.guestAddresses[0]?.country,
        zip: data.guestAddresses[0]?.zip,
        apartment: data.guestAddresses[0]?.apartment
      }
    ],
    guestNotes: [
      {
        message: data.guestNotes[0]?.message
      }
    ],
    noOfGuestsAllowed: data.noOfGuestsAllowed
  };
};

const guestData = (data: Guest[]) => {
  const guests: Guest[] = [];
  // eslint-disable-next-line array-callback-return
  data?.map((item: Guest) => {
    guests.push({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      mobile: item.mobile,
      guestGroupTags: item.guestGroupTags,
      rsvpAnswers: item.rsvpAnswers
    });
  });
  return guests;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapDataToRsvpCountDropdown = (data: any): { value: number; label: string } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data.map((item: any) => {
    return {
      value: item.event.id,
      label: item.event.name,
      fontFamily: item.fontFamily
    };
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const flattenedGuests = (guest: any) => {
  const flattenedArray: Guest[] = [];
  guest.forEach((res: GuestGroup) => {
    res.guests.forEach((item: Guest) => {
      flattenedArray.push(item);
    });
  });
  return flattenedArray;
};

export const filterGuests = (guestGroup: GuestGroup[], filter: any) => {
  console.log(guestGroup, filter);
  // Filter by event name and invitation
  let filteredGroup = guestGroup;

  // Base Condition 1 if event not selected
  filteredGroup =
    filter.eventId === 0 && filter.invitation === 'Invited'
      ? guestGroup.filter((group) => group.invitedFor.length > 0)
      : filter.eventId === 0 && filter.invitation === 'Not Invited'
      ? guestGroup.filter((group) => group.invitedFor.length === 0)
      : filteredGroup;

  // Base Condition 2 if event selected
  filteredGroup =
    filter.eventId !== 0 && filter.invitation === 'Invited'
      ? guestGroup.filter((group) =>
          group.invitedFor.some((e: any) => e.event.id === filter.eventId)
        )
      : filter.eventId !== 0 && filter.invitation === 'Not Invited'
      ? guestGroup.filter(
          (group) => !group.invitedFor.some((e: any) => e.event.id === filter.eventId)
        )
      : filteredGroup;

  // Base Condition 3 RSVP on filtered Guest Group (Yes / no with event)
  if (
    filter.invitation === 'Invited' &&
    filter.rsvpAnswers !== 'All' &&
    filter.rsvpAnswers !== 'no reply'
  ) {
    return flattenedGuests(filteredGroup).filter((g: Guest) =>
      filter.eventId !== 0
        ? g.rsvpAnswers?.some(
            (rsvp) => rsvp.answer === filter.rsvpAnswers && rsvp.eventId === filter.eventId
          )
        : g.rsvpAnswers?.some((rsvp) => rsvp.answer === filter.rsvpAnswers)
    );
  }

  // Base Condition 4 for searching
  if (filter.searchTerm !== '') {
    return flattenedGuests(filteredGroup).filter((g: Guest) => {
      const firstNameMatch =
        g.firstName && g?.firstName.toLowerCase().includes(filter.searchTerm.toLowerCase());
      const emailMatch = g.email && g.email.toLowerCase().includes(filter.searchTerm.toLowerCase());
      return firstNameMatch || emailMatch;
    });
  }

  if (filter.invitation === 'Invited' && filter.rsvpAnswers === 'no reply') {
    return flattenedGuests(filteredGroup).filter(
      (g: Guest) => !g.rsvpAnswers?.some((rsvp) => rsvp.eventId === filter.eventId)
    );
  }

  console.log(flattenedGuests(filteredGroup));

  return flattenedGuests(filteredGroup);
};
