import httpService from '@viteplan/services/http.service';

export const loggedInUser = () => {
  const loggedIn = JSON.parse(localStorage.getItem('user') || '{}');
  const user = Object.keys(loggedIn).length !== 0 ? true : false;
  return user;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forgotPassword = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`forgot-password`, body, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const verifyForForgotPassword = (uniqueId: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`forgot-password-user/${uniqueId}/check`, config).then((res: any) => res);
};

export const getVerfiedUserForForgotPassword = (uniqueId: string, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`forgot-password-user/${uniqueId}`, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePassword = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`set-password`, body, config).then((res: any) => res);
};
